/* account section css start */
.account-section {
  @include d-flex;
  min-height: 100vh;
  .left {
    padding: 100px 50px;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    order: 1;
    @include media(991px) {
      width: 100%;
      order: 2; }
    @include media(575px) {
      padding: 90px 30px; }
    .top-el, .bottom-el {
      position: absolute;
      left: 0;
      width: 100%;
      z-index: -1;
      opacity: 0.25;
      img {
        max-height: 200px; } }
    .top-el {
      top: 0;
      text-align: right; }
    .bottom-el {
      bottom: 0; }
    .account-form-area {
      width: 60%;
      @include media(1650px) {
        width: 70%; }
      @include media(1399px) {
        width: 100%; }
      @include media(991px) {
        width: 60%; }
      @include media(767px) {
        width: 80%; }
      @include media(575px) {
        width: 100%; } } }
  .account-logo {
    img {
      max-height: 110px; } }
  .right {
    width: 60%;
    position: relative;
    z-index: 1;
    padding: 100px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2;
    @include media(991px) {
      width: 100%;
      order: 1; }
    .content {
      width: 50%;
      @include media(1399px) {
        width: 80%; }
      @include media(575px) {
        width: 100%; } }
    .title {
      font-size: rem(72px);
      line-height: 1.2;
      @include media(1199px) {
        font-size: rem(62px); }
      @include media(991px) {
        font-size: rem(56px); }
      @include media(767px) {
        font-size: rem(48px); }
      @include media(575px) {
        font-size: rem(42px); } }
    &::before {
      @include positionTwo;
      background-color: $base-color-two;
      opacity: 0.75;
      z-index: -1; } } }
/* account section css end */
