/* card css start */
.custom--card {
  background-color: #fff;
  box-shadow: none;
  border: 1px solid $border-color;
  @include border-radius(5px);
  .card-header {
    background-color: #fff;
    padding: rem(10px) rem(15px); }
  .card-body {
    padding: rem(15px); } }
/* card css end */
