/* section css start */
.location-section {
  position: relative;
  z-index: 1;
  background-position-y: top;
  background-position-x: -300px;
  &::before {
    @include positionTwo;
    background-color: #fff;
    opacity: 0.7;
    z-index: -1; } }
.location-card {
  position: relative;
  &::before {
    @include positionTwo;
    background: #ff7e5f;
    background: -webkit-linear-gradient(to top, rgba($base-color-two, 0.85), rgba($base-color-two, 0.1));
    background: linear-gradient(to top, rgba($base-color-two, 0.85), rgba($base-color-two, 0.1)); }
  .overlay-content {
    position: absolute;
    padding: rem(20px);
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-flow: wrap;
    align-content: space-between;
    .top, .bottom {
      width: 100%; } } }
.location-slider {
  .slick-list {
    margin: 0 rem(-10px); }
  .single-slide {
    margin: 0 rem(10px); }
  .location-card {
    height: 375px;
    @extend %transition;
    &:hover {
      @include transform(scale(1.03, 1.03)); }
    img {
      width: 100%;
      height: 100%;
      @include object-fit; } }
  .slick-arrow {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #fff;
    color: $para-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(18px);
    top: 45%;
    z-index: 1;
    @extend %bs-5;
    @extend %transition;
    &:hover {
      @extend %base-color;
      color: #ffff; }
    &.prev {
      left: 15px; }
    &.next {
      right: 15px; } } }
.location-card {
  overflow: hidden;
  .location-name {
    margin-bottom: rem(5px); }
  .location-badge {
    background-color: rgba(#fff, 0.25);
    color: #fff;
    i {
      @extend %text--base; } } }
/* section css end */

/* property section css start */
.property-section {
  position: relative;
  z-index: 1;
  .bg-el {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0.15;
    z-index: -1;
    img {
      max-height: 300px; } }
  .bg-el2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    opacity: 0.15;
    text-align: right;
    z-index: -1;
    img {
      max-height: 300px; } } }
.property-card {
  background-color: #fff;
  box-shadow: 0 3px 15px rgba($base-color-two, 0.1);
  overflow: hidden;
  &__content {
    padding: rem(20px); } }
.property-slider {
  .slick-list {
    margin: rem(-10px); }
  .single-slide {
    margin: rem(10px); }
  .slick-dots {
    @include d-flex;
    justify-content: center;
    margin-top: 15px;
    li {
      margin: 0 5px;
      &.slick-active {
        button {
          background-color: $base-color;
          width: 25px; } }
      button {
        font-size: 0;
        width: 15px;
        height: 6px;
        background-color: #ddd;
        @include border-radius(999px);
        @extend %transition; } } } }
.property-card {
  &:hover {
    .property-card__thumb {
      img {
        @include transform(scale(1.05, 1.05)); } } }
  &__thumb {
    height: 200px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      @include object-fit;
      @extend %transition; } } }
/* property section css end */

/* best plan section css start */
.best-trip-section {
  background-position-y: 10%;
  position: relative;
  z-index: 1;
  &::before {
    @include positionTwo;
    background-color: #fff;
    opacity: 0.8;
    z-index: -1;
    display: none;
    @include media(1199px) {
      display: block; } } }
.best-trip-card {
  padding: rem(15px);
  background-color: $base-color-two;
  box-shadow: 0 5px 35px rgba(#000, 0.1);
  @include border-radius(10px);
  color: #fff;
  position: relative;
  overflow: hidden;
  h6, h5, h4, h3, h2 {
    color: #fff; }
  &__badge {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 100px;
    height: 100px;
    background-color: $base-color;
    color: #fff;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    text-align: right;
    padding-right: 15px;
    padding-top: 10px; }
  .thumb {
    height: 245px;
    @extend %bs-8;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      @include object-fit; } }
  .content {
    padding: rem(25px) rem(10px) rem(10px) rem(10px);
    .bottom {
      padding-top: rem(10px);
      margin-top: rem(15px);
      border-top: 1px solid rgba(#fff, 0.15); } }
  .price {
    font-size: rem(24px);
    line-height: 1;
    font-weight: 500; } }
.ratings {
  i {
    color: #FAC42D; } }

.best-trip-slider {
  .slick-lsit {
    margin: 0 rem(-15px); }
  .single-slide {
    padding: 0 rem(10px); }
  .slick-arrow {
    width: 45px;
    height: 45px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(20px);
    @include border-radius(50%);
    position: absolute;
    top: 30%;
    z-index: 1;
    @extend %transition;
    &:hover {
      @extend %base-color;
      color: #fff; }
    &.prev {
      left: -10px;
      @include media(1199px) {
        left: 15px; } }
    &.next {
      right: -10px;
      @include media(1199px) {
        right: 15px; } } } }

/* best plan section css end */


/* how work section css start */
.how-work-section {
  position: relative;
  z-index: 1;
  &::before {
    @include positionTwo;
    background-color: #fff;
    z-index: -1;
    opacity: 0.85; }
  .left-el, .right-el {
    position: absolute;
    bottom: 0;
    z-index: -1;
    img {
      max-height: 300px;
      @include media(1399px) {
        max-width: 200px;
        opacity: 0.25; } } }
  .left-el {
    left: 0; }
  .right-el {
    right: 0; } }
.work-card {
  @include d-flex;
  justify-content: center;
  align-items: center;
  &__number {
    width: rem(35px);
    height: rem(35px);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @extend %base-color-two;
    color: #fff;
    font-size: rem(18px);
    @include border-radius(50%);
    margin-right: rem(10px); }
  .title {
    @include media(1199px) {
      font-size: rem(18px); } } }
/* how work section css end */

/* testimonial section css start */
.testimonial-item {
  @include d-flex;
  align-items: center;
  position: relative;
  padding: 40px 20px;
  z-index: 1;
  @include media(1199px) {
    padding: 10px 20px; }
  @include media(991px) {
    padding: 30px; }
  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    background-color: transparent;
    border: 10px solid $base-color;
    z-index: -1;
    @include media(991px) {
      width: 100%;
      border-width: 5px; } }
  .thumb {
    width: 35%;
    @include media(991px) {
      width: 100%; } }
  .content {
    width: 65%;
    padding: 30px 30px 30px 150px;
    background-color: #fff;
    position: relative;
    @include media(991px) {
      width: 100%;
      padding: 30px 30px 30px 70px; }
    @include media(575px) {
      padding: 30px 0; }
    &::before {
      position: absolute;
      content: "\f10d";
      top: 20px;
      left: 20px;
      font-size: rem(90px);
      line-height: 1;
      font-family: 'Line Awesome Free';
      font-weight: 900;
      @extend %text--base;
      @include media(991px) {
        font-size: rem(48px);
        left: 0; }
      @include media(575px) {
        display: none; } }
    .testimonial-seech {
      font-size: rem(20px);
      font-family: $heading-font; } }
  .traveller-review {} }
.testimonial-slider {
  .slick-arrow {
    width: 45px;
    height: 45px;
    background-color: $base-color;
    color: #fff;
    box-shadow: 0 0 5px rgba(#000, 0.15);
    @include border-radius(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40%;
    z-index: 1;
    @include media(991px) {
      top: auto;
      bottom: -20px; }
    &.prev {
      right: -15px;
      @include media(991px) {
        right: 52%; } }
    &.next {
      right: -15px;
      top: 55%;
      @include media(991px) {
        top: auto;
        right: 44%; } } } }
/* testimonial section css end */


/* video section css start */
.video--btn {
  width: 100px;
  height: 100px;
  border: 2px solid #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: rem(48px);
  @include border-radius(50%); }
/* video section css end */

/* blog section css start */
.blog-card {
  overflow: hidden;
  position: relative;
  max-height: 350px;
  &::before {
    @include positionTwo;
    background: $base-color-two;
    background: -webkit-linear-gradient(to top, rgba($base-color-two, 0.85), rgba($base-color-two, 0.1));
    background: linear-gradient(to top, rgba($base-color-two, 0.85), rgba($base-color-two, 0.1)); }
  &__thumb {
    img {
      width: 100%;
      height: 100%;
      @include object-fit; } }
  &__content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: rem(30px); } }
/* blog section css end */
