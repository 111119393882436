/* contact section css start */
.contact-card {
  padding: rem(30px);
  background-color: #fff;
  box-shadow: 0 10px 35px rgba(#000, .1);
  @extend %bs-8;
  border: 1px solid $border-color;
  height: 100%;
  &__header {
    padding-bottom: rem(10px);
    margin-bottom: rem(20px);
    border-bottom: 1px solid $border-color;
    @include d-flex;
    align-items: center;
    .icon {
      width: 55px;
      i {
        font-size: rem(42px);
        @extend %text--base; } }
    .title {
      width: calc(100% - 55px); } }
  a {
    color: $para-color;
    &:hover {
      @extend %text--base; } } }
.social-media-list {
  margin: -5px -10px;
  li {
    padding: 5px 10px;
    a {
      width: 40px;
      height: 40px;
      @extend %bs-5;
      display: flex;
      justify-content: center;
      align-items: center;
      @extend %base-color-two;
      color: #fff;
      @extend %transition;
      &:hover {
        @extend %base-color;
        color: #fff; } } } }
.dot--bg {
  position: relative;
  z-index: 1;
  &::before {
    position: absolute;
    content: '';
    background: transparent;
    background-image: radial-gradient($base-color-two 25%, transparent 0), radial-gradient($base-color-two 25%, transparent 0);
    background-size: 10px 10px;
    background-position: 0 0, 10px 10px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.05;
    z-index: -1; } }
/* contact section css end */

/* map section css start */
.google-map-section {
  position: relative;
  filter: grayscale(100%);
  -moz-backface-visibility: hidden;
  @extend %transition;
  &:hover {
    filter: grayscale(0%); }
  iframe {
    width: 100%;
    height: 450px; } }
/* map section css end */

/* contact form section css start */
.contact-form {
  padding: 50px;
  background-color: #fff;
  box-shadow: 0 10px 35px rgba(#000, .1);
  @extend %bs-8;
  border: 1px solid $border-color;
  @include media(1199px) {
    padding: 30px; }
  @include media(480px) {
    padding: 20px; } }
/* contact form section css end */
