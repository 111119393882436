/* header start */
.header {
  background-color: #fff;
  &.menu-fixed {
    .header__top {
      display: none; }
    .header__bottom {
      background-color: $base-color-two; } }
  &__bottom {
    border-bottom: none;
    padding: rem(5px) 0;
    @include media(1199px) {
      padding: rem(10px) 0; } }
  .site-logo {
    img {
      max-width: rem(175px);
      max-height: rem(60px);
      @include media(1199px) {
        max-width: rem(150px); } } }
  .main-menu {
    margin-left: rem(70px);
    @include media(1199px) {
      margin-left: 0;
      padding: rem(15px) 0; }
    li {
      position: relative;
      @include media(1199px) {
        border-bottom: 1px solid rgba(#ffffff, 0.15); }
      &:last-child {
        a {
          padding-right: 0; } }
      &.menu_has_children {
        position: relative;
        &.open {
          .sub-menu {
            display: block; } }
        >a {
          padding-right: rem(25px);
          @include media(1199px) {
            display: block; }
          &::before {
            position: absolute;
            content: "\f067";
            font-family: "Line Awesome Free";
            font-weight: 900;
            top: 30px;
            right: 0;
            color: #ffffff;
            @extend  %transition;
            @include media(1199px) {
              display: block;
              top: rem(9px); } } }
        &:hover {
          >a::before {
            content: "\f068";
            color: $base-color; } } }
      a {
        font-family: $para-font;
        font-weight: 500;
        text-transform: uppercase;
        padding: rem(15px) rem(15px) rem(15px) 0;
        font-size: rem(15px);
        color: $heading-color;
        @include media(1199px) {
          color: #ffffff;
          padding: rem(8px) 0;
          display: block; }
        &:hover,&:focus {
          color: $base-color; } }
      .sub-menu {
        position: absolute;
        width: 220px;
        top: 105%;
        left: 0;
        z-index: 9999;
        background-color: #fff;
        padding: rem(10px) 0;
        -webkit-box-shadow: 0px 18px 54px -8px rgba(0,0,0,0.15);
        box-shadow: 0px 5px 25px 2px rgba(0,0,0,0.1);
        @include border-radius(5px);
        @extend  %transition;
        opacity: 0;
        visibility: hidden;
        border: 2px solid #e5e5e5;
        @include media(1199px) {
          opacity: 1;
          visibility: visible;
          display: none;
          position: static;
          @include transition(none);
          width: 100%;
          background-color: $base-color-two;
          border: none; }
        &::before {
          position: absolute;
          content: '';
          top: -19px;
          left: 20px;
          border-width: 10px 10px 10px 10px;
          border-style: solid;
          border-color: transparent transparent #fff transparent;
          @include media(1199px) {
            display: none; } }
        li {
          border-bottom: 1px dashed #e5e5e5;
          @include media(1199px) {
            border-color: rgba(#fff, 0.15); }
          &:last-child {
            border-bottom: none; }
          a {
            padding: rem(8px) rem(25px);
            display: block;
            color: $para-color;
            position: relative;
            font-size: rem(15px);
            text-transform: capitalize;
            @extend  %transition;
            @include media(1199px) {
              color: #fff; }
            &::before {
              position: absolute;
              content: '';
              top: 0;
              left: rem(-4px);
              width: rem(4px);
              height: 100%;
              background-color: $base-color;
              opacity: 0;
              @extend  %transition; }
            &:hover {
              background-color: rgba($base-color, 0.05);
              color: $base-color;
              &::before {
                opacity: 1;
                left: 0; } } } }
        li+li {
          margin-left: 0; } }
      &:hover .sub-menu {
        top: 100%;
        opacity: 1;
        visibility: visible; } }
    li+li {
      margin-left: rem(20px);
      @include media(1199px) {
        margin-left: 0; } } }
  .nav-right {
    padding-left: rem(50px);
    @include d-flex;
    align-items: center;
    @include media(1199px) {
      padding-left: 0; } } }
.language-select {
  background-color: transparent;
  color: $heading-color;
  height: 45px;
  padding: 10px;
  border: 1px solid rgba(#fff, 0.15);
  @include border-radius(3px);
  @include media(1199px) {
    color: #fff; }
  option {
    background-color: $base-color-two;
    color: #fff; } }
.navbar-collapse {
  @include media(1199px) {
    background-color: $bg-one;
    padding: 0 rem(30px) rem(20px) rem(30px); }
  @include media(767px) {
    max-height: rem(320px);
    overflow: auto; } }
.navbar-toggler {
  padding: 0;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none; } }
.menu-toggle {
  margin: 10px 0;
  position: relative;
  display: block;
  width: rem(35px);
  height: rem(20px);
  cursor: pointer;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: #000;
  font-size: 0;
  @include transition(all 0.25s ease-in-out);
  cursor: pointer; }
.menu-toggle:before, .menu-toggle:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: currentColor;
  @include transform(translate(-50%, -50%));
  transition: -webkit-transform 0.25s ease-in-out;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  -moz-transition: -webkit-transform 0.25s ease-in-out;
  -ms-transition: -webkit-transform 0.25s ease-in-out;
  @include media(1199px) {
    background-color: #000; } }
// create animation for when menu is fixed
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }
@include keyframes (fadeInDown) {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }
/* header end */
