/* dashboard section css start */
.d-widget {
  padding: rem(15px);
  background-color: #fff;
  @extend %bs-8;
  border: 1px;
  @include d-flex;
  align-items: center;
  box-shadow: 0 5px 35px rgba(#000, 0.05);
  @extend %transition;
  &:hover {
    box-shadow: 0 8px 45px rgba(#000, 0.1);
    .d-widget__icon {
      @extend %base-color;
      color: #fff; } }
  &__icon {
    width: rem(60px);
    height: rem(60px);
    background-color: rgba($base-color, 0.15);
    @extend %text--base;
    @extend %bs-5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(32px);
    @extend %transition; }
  &__content {
    width: calc(100% - #{rem(60px)});
    padding-left: rem(20px);
    .amount {
      font-size: rem(32px); } } }

/* dashboard section css end */
