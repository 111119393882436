/* table css start */
.custom--table {
  background-color: #fff;
  @include border-radius(8px);
  &.white-space-nowrap {
    th {
      white-space: nowrap; } }
  thead {
    background-color: #f0f3fb;
    th {
      border-top: none;
      padding: rem(10px) rem(20px);
      color: lighten($para-color, 10%);
      background-color: transparent;
      border: none;
      font-size: rem(13px);
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
      &:first-child {
        // +border-radius(5px 0 0 5px)
        text-align: left; }
      &:last-child {
        // +border-radius(0 5px 5px 0)
        text-align: right; } } }
  tbody {
    td {
      border-top: none;
      border-bottom: 1px solid rgba(#000000, 0.07);
      padding: rem(15px) rem(20px);
      color: $para-color;
      vertical-align: middle;
      font-size: rem(15px);
      text-align: center;
      &:first-child {
        text-align: left; }
      &:last-child {
        text-align: right; } }
    tr {
      &:nth-child(even) {
        background-color: #afb1b50d; }
      &:last-child {
        td {
          border-bottom: none; } } } } }
.table-hotel {
  @include media(991px) {
    justify-content: flex-end; }
  .thumb {
    width: 65px;
    height: 45px;
    overflow: hidden;
    @extend %bs-8;
    padding: 3px;
    border: 1px solid $border-color;
    box-shadow: 0 3px 15px rgba(#000, 0.15);
    img {
      width: 100%;
      height: 100%;
      @include object-fit;
      @extend %bs-8; } }
  .content {
    padding-left: rem(15px); } }
// table responsive
[data-label] {
  position: relative;
  &::before {
    position: absolute;
    content: attr(data-label);
    font-weight: 700;
    color: #000000;
    top: 0;
    left: 0;
    padding: rem(13px) rem(15px);
    display: none;
    font-size: rem(12px); } }
.table-responsive--md {
  @media (max-width: 991px) {
    thead {
      display: none; }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: aliceblue; } } }
    tr {
      th, td {
        display: block;
        padding-left: 45%!important;
        text-align: right!important;
        border-top: 1px solid rgba(#000000, 0.08) !important;
        &:first-child {
          border-top: none !important; } } }
    [data-label] {
      &::before {
        display: block; } } } }
.table-responsive--sm {
  @media (max-width: 767px) {
    thead {
      display: none; }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: aliceblue; } } }
    tr {
      th, td {
        display: block;
        padding-left: 45%!important;
        text-align: right!important;
        border-top: 1px solid rgba(#000000, 0.08) !important;
        &:first-child {
          border-top: none !important; } } }
    [data-label] {
      &::before {
        display: block; } } } }
*[class*="table-responsive--"] {
  &.data-label--none {
    @media (max-width: 1199px) {
      tr {
        th, td {
          padding-left: .75rem; } } } } }
/* table css end */
