/* reset css start */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&family=Roboto:wght@400;500&display=swap');

// font-family: 'Playfair Display', serif;
// font-family: 'Roboto', sans-serif;

$heading-font: 'Playfair Display', serif;
$para-font: 'Roboto', sans-serif;

html {
  scroll-behavior: smooth; }
body {
  font-family: $para-font;
  color: $para-color;
  font-size: rem(16px);
  padding: 0;
  margin: 0;
  font-weight: 400;
  position: relative;
  line-height: 1.7;
  background-color: #fff;
  @include transition(all 0.5s);
  overflow-x: hidden;
  @extend %transition;
  &.page-trns-active {
    position: relative;
    &::after {
      position: absolute;
      content: "\f110";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      color: #fff;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      font-size: rem(72px);
      z-index: 999;
      animation: spinRoll 1s infinite linear; }
    &::before {
      @include positionTwo;
      background-color: $base-color-two;
      z-index: 99;
      opacity: 0.65; } } }
@include keyframes(spinRoll) {
  from {
    @include transform(rotate(0deg)); }
  to {
    @include transform(rotate(360deg)); } }
section {
  background-color: #fff; }
img {
  max-width: 100%;
  height: auto;
  user-select: none; }
select {
  cursor: pointer; }
ul, ol {
  padding: 0;
  margin: 0;
  list-style: none; }
button {
  cursor: pointer; }
*:focus {
  outline: none; }
button {
  border: none; }
button:focus {
  outline: none; }
span {
  display: inline-block; }
a:hover {
  color: $base-color; }
/* reset css end */
