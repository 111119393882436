/* hotel deatils section css start */
.hotel-details-thumb {
  position: relative;
  height: 350px;
  &:hover {
    .full-view {
      @include transform(translate(-50%, -50%) scale(1,1));
      opacity: 1;
      visibility: visible; } }
  img {
    height: 100%;
    width: 100%;
    @include object-fit; }
  .full-view {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%) scale(0.5,0.5));
    background-color: rgba($base-color-two, 0.85);
    color: #fff;
    padding: rem(8px) rem(30px);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    @include border-radius(999px);
    opacity: 0;
    visibility: hidden;
    @extend %transition;
    i {
      font-size: rem(22px);
      margin-right: 6px; } } }
.hotel-details-thumb-slider {
  .slick-arrow {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    @extend %bs-5;
    z-index: 1;
    margin-top: -20px;
    &.prev {
      left: 20px; }
    &.next {
      right: 20px; } } }
/* hotel deatils section css end */

/* hotel-details-box css start */
.hotel-details-box {
  padding: 30px 0;
  border-bottom: 1px solid $border-color;
  &:first-child {
    padding-top: 0; }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .aminities-list {
    margin-top: 10px;
    li + li {
      margin-top: 6px; }
    li {
      font-size: rem(14px);
      &::before {
        content: "\f058";
        font-family: 'Line Awesome Free';
        font-weight: 900;
        margin-right: 5px;
        color: $success; } } } }
.hotel-details-map {
  padding: rem(15px);
  background-color: #fff;
  border: 1px solid $border-color;
  box-shadow: 0 10px 35px rgba(#000, .1);
  @extend %bs-8;
  iframe {
    width: 100%;
    height: 450px; } }
.aminities-grid {
  margin: -15px; }
.aminities-item {
  width: calc(100% / 4);
  padding: 15px;
  @include media(1199px) {
    width: calc(100% / 3); }
  @include media(575px) {
    width: calc(100% / 2); } }
/* hotel-details-box css end */

/* hotel-details-sidebar css start */
.hotel-details-sidebar {
  margin-top: -150px;
  position: sticky;
  z-index: 1;
  top: 50px;
  @include media(991px) {
    margin-top: 0; } }
.reserve-widget {
  padding: rem(15px);
  background-color: #fff;
  border: 1px solid $border-color;
  @extend %bs-8;
  box-shadow: 0 10px 35px rgba(#000, .1);
  .top {
    padding: rem(20px);
    background-color: #f1f1f1;
    @extend %bs-5;
    position: relative;
    overflow: hidden;
    .hotel-details-offer-badge {
      position: absolute;
      top: -2px;
      right: -2px;
      width: 100px;
      height: 100px;
      background-color: $base-color;
      color: #fff;
      clip-path: polygon(0 0, 100% 0, 100% 100%);
      text-align: right;
      padding-right: 15px;
      padding-top: 10px; } }
  .price {
    font-weight: 500;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    del {
      font-weight: 400;
      font-size: 18px;
      color: #777;
      margin-right: 10px; }
    sub {
      font-weight: 400;
      font-size: 50%;
      color: #777;
      margin-left: 5px; } } }
.book-widget {
  padding: rem(30px);
  @extend %bs-8;
  @extend %base-color-two;
  i {
    font-size: rem(56px);
    line-height: 1; } }
/* hotel-details-sidebar css end */
