/* footer section css start */
.footer {
  position: relative;
  padding-top: 80px;
  z-index: 1;
  &::before {
    @include positionTwo;
    background-color: #fff;
    opacity: 0.85;
    z-index: -1; }
  &__bottom {
    padding: 20px 0;
    border-top: 1px solid $border-color;
    margin-top: 50px; } }
.footer-logo {
  img {
    max-height: 70px; } }
.footer-widget {
  &__title {
    margin-bottom: 25px;
    position: relative;
    z-index: 1;
    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $border-color;
      z-index: -1; }
    span {
      background-color: #fff; } } }
.footer-menu-list {
  li + li {
    margin-top: 5px; }
  li {
    a {
      color: $para-color;
      font-size: rem(15px);
      &::before {
        content: '- '; }
      &:hover {
        @extend %text--base; } } } }
.footer-contact-list {
  @include d-flex;
  margin: -8px;
  > li {
    width: 50%;
    @include d-flex;
    padding: 8px;
    @include media(1199px) {
      width: 100%; }
    .icon {
      width: 30px;
      font-size: rem(20px); }
    .content {
      width: calc(100% - 30px);
      a {
        color: $para-color;
        word-break: break-all;
        &:hover {
          @extend %text--base; } } } } }
.social-media-list {
  margin: -5px;
  li {
    padding: 5px;
    a {
      font-size: rem(20px);
      color: $para-color;
      &:hover {
        @extend %text--base; } } } }
