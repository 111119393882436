@import "./_reset";
/* global css strat */
@import "./_color";
@import "./_bg";
// margin global css
.mb-30 {
  margin-bottom: 30px; }
.mb-none-30 {
  margin-bottom: -30px; }

// padding global css
.pt-25 {
  padding-top: 25px; }
.pb-25 {
  padding-bottom: 25px; }
.pt-50 {
  padding-top: 50px; }
.pb-50 {
  padding-bottom: 50px; }
.pt-100 {
  padding-top: 100px;
  @include media(767px) {
    padding-top: 80px; } }
.pb-100 {
  padding-bottom: 100px;
  @include media(767px) {
    padding-bottom: 80px; } }
.pt-120 {
  padding-top: 120px; }
.pb-120 {
  padding-bottom: 120px; }
.bg_img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.section--bg {
  // background-color: #F5F7FA
  // background-color: #eef2f9
  background-color: #f0f3fb; }
.section--bg2 {
  background-color: $base-color-two; }
.section-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.dark-overlay {
  position: relative;
  z-index: 1;
  &::before {
    @include positionTwo;
    background-color: $base-color-two;
    opacity: 0.4;
    z-index: -1; } }

.opacity20 {
  opacity: 0.2; }
.opacity30 {
  opacity: 0.3; }
.opacity50 {
  opacity: 0.5; }
.bg--one {
  background-color: $bg-one; }
.slick-arrow {
  cursor: pointer; }
.z-index-2 {
  z-index: 2; }
.main-wrapper {
  position: relative;
  z-index: 1; }
.section-header {
  margin-bottom: rem(45px); }
.section-title {
  font-size: rem(42px);
  @include media(991px) {
    font-size: rem(38px); }
  @include media(767px) {
    font-size: rem(36px); }
  @include media(575px) {
    font-size: rem(32px); } }
.section-subtitle {
  font-family: $heading-font;
  font-size: rem(16px);
  font-weight: 500;
  &.border-left {
    padding-left: rem(35px);
    position: relative;
    z-index: 1;
    &::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 25px;
      height: 2px;
      background-color: $base-color;
      margin-top: -1px; } } }
a.text-white {
  &:hover {
    color: $base-color !important; } }
.text--link {
  text-decoration: underline;
  &:hover {
    text-decoration: underline; } }
.has--link {
  position: relative;
  .item--link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; } }
.custom--dropdown {
  .dropdown-toggle {
    &.no-arrow {
      &::after {
        display: none; } }
    &::after {
      content: "\f107";
      border: none;
      font-family: 'Line Awesome Free';
      font-weight: 900;
      @include transform(translateY(3px)); } }
  .dropdown-menu {
    border-color: #e5e5e5;
    box-shadow: 0 5px 10px rgba(#000, 0.05);
    li {
      border-bottom: 1px dashed #e5e5e5;
      &:last-child {
        border-bottom: none; }
      .dropdown-item {
        color: $para-color;
        font-size: rem(14px);
        &:hover {
          color: $base-color;
          background-color: rgba($base-color, 0.05); } } } } }

.custom--accordion {
  .accordion-item + .accordion-item {
    margin-top: rem(20px); }
  .accordion-item {
    border: 1px solid rgba($base-color, 0.5);
    @include border-radius(5px);
    &:first-child {
      .accordion-button {
        border-top: none; } }
    &:last-child {
      .accordion-button {
        border-bottom: none; } } }
  .accordion-button {
    padding: rem(20px) rem(25px);
    background-color: rgba($base-color, 0.05);
    font-size: rem(18px);
    position: relative;
    text-align: left;
    &::after {
      position: absolute;
      top: rem(20px);
      right: rem(13px);
      font-size: rem(17px);
      content: '\f107';
      font-family: 'Line Awesome Free';
      font-weight: 900;
      background-image: none;
      color: #000; }
    &:not(.collapsed) {
      background-color: $base-color;
      color: #fff;
      &::after {
        color: #fff; } }
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: transparent; } }
  .accordion-body {
    padding: rem(20px) rem(25px); } }
.page-breadcrumb {
  @include d-flex;
  margin-top: rem(15px);
  li {
    color: rgba(#ffffff, 0.8);
    text-transform: capitalize;
    &::after {
      content: '-';
      color: #ffffff;
      margin: 0 rem(5px); }
    &:first-child {
      &::before {
        content: "\f015";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: $base-color;
        margin-right: rem(6px); } }
    &:last-child {
      &::after {
        display: none; } }
    a {
      color: #ffffff;
      text-transform: capitalize;
      &:hover {
        color: $base-color; } } } }
.cmn-list {
  li + li {
    margin-top: rem(15px); }
  li {
    position: relative;
    padding-left: rem(35px);
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      font-family: 'Line Awesome Free';
      font-weight: 900;
      content: "\f058";
      font-size: rem(28px);
      color: $base-color;
      margin-right: rem(8px);
      line-height: 1; } } }
.cmn-list-two {
  li {
    padding: rem(6px) rem(15px);
    font-size: rem(14px);
    &:nth-child(even) {
      background-color: #EBF5F5; } } }
.number-list {
  list-style: decimal;
  padding-left: rem(18px);
  li + li {
    margin-top: rem(10px); } }

.disc-list {
  li + li {
    margin-top: rem(10px); }
  li {
    position: relative;
    padding-left: rem(15px);
    &::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: rem(6px);
      height: rem(6px);
      margin-top: rem(-3px);
      @include border-radius(50%);
      background-color: #bdbdbd; } } }
.square-list {
  li + li {
    margin-top: 10px; }
  li {
    padding-left: 25px;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      top: 5px;
      left: 0;
      width: 14px;
      height: 14px;
      background-color: #e6e6e6; }
    &::after {
      position: absolute;
      content: '';
      top: 9px;
      left: 4px;
      width: 14px;
      height: 14px;
      background-color: rgba($base-color, 0.45); } } }

.caption-list {
  li {
    display: flex;
    flex-wrap: wrap;
    padding: rem(10px) 0;
    font-size: rem(15px);
    border-bottom: 1px dashed $border-color;
    &:first-child {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    .caption {
      width: 30%;
      font-family: $heading-font;
      font-weight: 700;
      font-size: rem(14px);
      position: relative;
      @include media(480px) {
        width: 35%; }
      &::after {
        position: absolute;
        content: ':';
        top: 0;
        right: 0; } }
    .value {
      width: 70%;
      padding-left: rem(15px);
      @include media(480px) {
        width: 65%; } } } }
.caption-list-two {
  padding: rem(10px) rem(15px);
  background-color: rgba($base-color, 0.1);
  @include border-radius(5px);
  li {
    font-family: $heading-font;
    font-weight: 500;
    color: $heading-color;
    font-size: rem(14px);
    padding: rem(8px) 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px dashed darken($border-color, 10%);
    &:first-child {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    .caption {
      width: 20%;
      position: relative;
      font-weight: 700;
      padding-right: 10px;
      &::after {
        position: absolute;
        content: ':';
        top: 0;
        right: 0; } }
    .value {
      width: 80%;
      padding-left: 20px; } } }
%transition {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
%base-color {
  background-color: $base-color; }
%base-color-two {
  background-color: $base-color-two; }
%text--base {
  color: $base-color; }

%bs-5 {
  @include border-radius(5px); }
%bs-8 {
  @include border-radius(8px); }

.object-fit--cover {
  @include object-fit; }
.pagination {
  margin: rem(-5px) rem(-7px);
  flex-wrap: wrap;
  .page-item {
    margin: rem(5px) rem(7px);
    &.active {
      .page-link {
        background-color: $base-color;
        color: #fff; } }
    .page-link {
      width: rem(45px);
      height: rem(45px);
      display: flex;
      justify-content: center;
      align-items: center;
      @include border-radius(5px);
      border: 1px solid rgba($base-color, 0.25);
      color: $para-color;
      &:hover {
        background-color: $base-color;
        border-color: $base-color;
        color: #fff; } } } }
.pagination-md {
  .page-item {
    .page-link {
      width: rem(40px);
      height: rem(40px); } } }
.pagination-sm {
  .page-item {
    .page-link {
      width: rem(35px);
      height: rem(35px);
      font-size: rem(14px); } } }
// animation css
.shake {
  animation: shake 0.5s 1 linear; }
@include keyframes (shake) {
  0%, 100% {
    @include transform(translateX(0)); }
  10%, 30%, 50%, 70%, 90% {
    @include transform(translateX(-10px)); }
  20%, 40%, 60%, 80% {
    @include transform(translateX(10px)); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }
@include keyframes (fadeInUp) {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }
@include keyframes (fadeInLeft) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }
@include keyframes (fadeInRight) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }
@include keyframes(slideInLeft) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }
@include keyframes(slideInRight) {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
/* global css end */


/* preloader css start */
@-webkit-keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0); }

  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0); }

  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-webkit-keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0); }

  30% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0); }

  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0); }

  30% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0); }

  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

/* Styles */

.preloader {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: white;
  text-align: center;

  .preloader-container {
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: auto;
    position: absolute;
    top: 44%;
    left: 0;
    position: relative;

    .animated-preloader {
      display: inline-block;
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      background: $base-color;
      border-radius: 50em;

      &:after {
        content: '';
        display: inline-block;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50em;
        background: white;
        -webkit-animation: preloader-inside-white 1s ease-in-out infinite;
        -ms-animation: preloader-inside-white 1s ease-in-out infinite;
        animation: preloader-inside-white 1s ease-in-out infinite; }

      &:before {
        content: '';
        display: inline-block;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        border-radius: 50em;
        background: $base-color;
        -webkit-animation: preloader-inside-red 1s ease-in-out infinite;
        -ms-animation: preloader-inside-red 1s ease-in-out infinite;
        animation: preloader-inside-red 1s ease-in-out infinite; } } } }
/* preloader css end */
