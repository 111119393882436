/* sidebar css start */
.sidebar {
  padding-left: 30px;
  @media (max-width: 991px) {
    padding-left: 0;
    margin-top: 50px; }
  .widget + .widget {
    margin-top: 50px;
    @media (max-width: 767px) {
      margin-top: 30px; } }
  .widget {
    padding: 30px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    .search-form {
      position: relative;
      input {
        width: 100%;
        border: 1px solid #e5e5e5; }
      .search-btn {
        position: absolute;
        color: $base-color;
        background-color: transparent;
        top: 0;
        right: 0;
        font-size: 18px;
        color: $para-color;
        width: 50px;
        height: 100%; } }
    .widget__title {
      position: relative;
      padding-bottom: 8px;
      margin-bottom: 25px;
      &::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 20px;
        height: 2px;
        background-color: $base-color; } }
    .map {
      iframe {
        width: 100%; } } }
  .list {
    li {
      &:first-child {
        padding-top: 0; }
      &:last-child {
        padding-bottom: 0;
        border-bottom: none; }
      padding: 10px 0;
      border-bottom: 1px solid #e5e5e5;
      .caption {
        font-weight: 600;
        &::after {
          content: ' :'; } }
      .info {
        float: right; } } }
  .categories__list {
    li {
      &:first-child {
        a {
          padding-top: 0; } }
      &:last-child {
        a {
          padding-bottom: 0;
          border-bottom: none; } }
      a {
        display: block;
        color: $para-color;
        padding: 10px 0;
        border-bottom: 1px solid #e5e5e5;
        span {
          float: right;
          &::before {
            content: '('; }
          &::after {
            content: ')'; } } } } }
  .archive__list {
    li {
      &:first-child {
        a {
          padding-top: 0; } }
      &:last-child {
        a {
          padding-bottom: 0;
          border-bottom: none; } }
      a {
        display: block;
        color: $para-color;
        padding: 10px 0;
        border-bottom: 1px solid #e5e5e5;
        &:hover {
          color: $base-color; }
        span {
          float: right;
          &::before {
            content: '('; }
          &::after {
            content: ')'; } } } } }
  .small-post-list {
    .small-post {
      @include d-flex;
      padding: 20px 0;
      border-bottom: 1px solid #e5e5e5;
      &:first-child {
        padding-top: 0; }
      &:last-child {
        padding-bottom: 0;
        border-bottom: none; }
      &__thumb {
        width: 58px; }
      &__content {
        width: calc(100% - 58px);
        padding-left: 20px;
        .post__title {
          a {
            font-size: 16px;
            font-weight: 600; } } } } }
  .tags {
    margin: -5px -2px;
    a {
      font-size: 16px;
      font-weight: 400;
      color: $para-color;
      padding: 10px 20px;
      border: 1px solid #e5e5e5;
      @include transition(all 0.3s);
      margin: 5px 2px;
      text-transform: capitalize;
      &:hover {
        background-color: $base-color;
        color: #ffffff;
        border-color: $base-color; } } } }
/* sidebar css end */


/* action-sidebar css start */
.action-sidebar {
  background-color: #fff;
  @extend %transition;
  @include media(991px) {
    padding: 30px;
    width: 350px;
    position: fixed;
    top: 75px;
    left: -105%;
    z-index: 99999;
    max-width: 100%;
    box-shadow: 5px 5px 10px rgba(#000, 0.15);
    max-height: calc(100vh - 75px);
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: darkgrey #e7e7e7;
    &::-webkit-scrollbar {
      width: 5px; }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      @include border-radius(999px); }
    &.active {
      left: 0; } } }
.action-widget {
  & + & {
    margin-top: rem(30px); }
  &__title {
    font-size: rem(16px);
    color: $heading-color;
    margin-bottom: rem(15px);
    padding-bottom: rem(5px);
    border-bottom: 1px solid lighten($border-color, 8%);
    position: relative;
    padding-right: 20px;
    cursor: pointer;
    &::after {
      position: absolute;
      content: "\f107";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      top: 0;
      right: 0;
      font-size: rem(14px); } }
  &__body {
    &.scroll--active {
      max-height: 200px;
      overflow: auto;
      scrollbar-width: thin;
      scrollbar-color: darkgrey #e7e7e7;
      &::-webkit-scrollbar {
        width: 5px; }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        @include border-radius(999px); } } } }

.action-sidebar-close {
  width: 30px;
  height: 30px;
  background-color: $base-color;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  @include media(991px) {
    display: inline-block; } }
.action-sidebar-open {
  padding: rem(8px) rem(25px);
  background-color: $base-color;
  color: #fff;
  align-items: center;
  justify-content: center;
  @include border-radius(3px);
  display: none;
  @include media(991px) {
    display: inline-flex; }
  i {
    font-size: rem(22px);
    margin-right: 5px; } }
/* action-sidebar css end */

/* property-sidebar css start */
.property-sidebar {
  position: sticky;
  top: 100px; }
.property-widget {
  padding: rem(15px);
  background-color: #fff;
  border: 2px solid $border-color;
  @include border-radius(5px); }
.agent {
  @include d-flex;
  &__thumb {
    width: 70px; }
  &__content {
    width: calc(100% - 70px);
    padding-left: rem(20px); } }
.agent-form {
  .form--control {
    height: 45px; } }
/* property-sidebar css end */


/* user-sidebar css start */
.user-widget {
  padding: rem(30px);
  @extend %base-color-two;
  @extend %bs-8;
  .thumb {
    max-height: 230px;
    overflow: hidden;
    @extend %bs-5; } }
.user-info-list {
  li {
    padding: 6px 0;
    border-bottom: 1px dashed rgba(#fff, 0.15);
    @include d-flex;
    align-items: center;
    &:first-child {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    i {
      width: 30px;
      font-size: rem(22px);
      @extend %text--base; }
    p {
      width: calc(100% - 30px);
      padding-left: 15px; } } }
.user-menu-widget {
  margin-top: rem(30px);
  padding: rem(15px);
  background-color: #fff;
  box-shadow: 0 5px 35px rgba(#000, 0.05);
  @extend %bs-8; }
.user-menu {
  li {
    border-bottom: 1px dashed lighten($border-color , 10%);
    &.active {
      border-bottom: none;
      a {
        @extend %base-color;
        color: #fff;
        @extend %bs-5; }
      &:hover {
        a {
          @extend %base-color;
          color: #fff; } } }
    &:last-child {
      border-bottom: none; }
    &:hover {
      a {
        background-color: rgba($base-color, 0.15);
        @extend %text--base;
        @extend %bs-5; } }
    a {
      @include d-flex;
      align-items: center;
      color: $para-color;
      @extend %transition;
      padding: 10px 15px;
      i {
        font-size: rem(18px);
        margin-right: 8px; } } } }
/* user-sidebar css end */
