/* search result section css start */
.hotel-card {
  & + & {
    margin-top: rem(15px); }
  @include d-flex;
  $a: 300px; // thumb width
  $b: 160px; // action width
  $c: ($a + $b);
  @extend %bs-8;
  overflow: hidden;
  box-shadow: 0 0 8px rgba($base-color-two, 0.1);
  position: relative;
  @include media(767px) {
    box-shadow: 0 0 5px rgba($base-color-two, 0.2); }
  &__offer-badge {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 100px;
    height: 100px;
    background-color: $base-color;
    color: #fff;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    text-align: right;
    padding-right: 15px;
    padding-top: 10px; }
  &__thumb {
    width: $a;
    @include media(1199px) {
      width: 200px; }
    @include media(767px) {
      width: 100%; }
    a {
      height: 100%; }
    img {
      width: 100%;
      height: 100%;
      @include object-fit; } }
  &__content {
    width: calc(100% - #{$c});
    padding: 20px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    @include media(1199px) {
      width: calc(100% - 360px); }
    @include media(767px) {
      width: 100%; }
    .title {
      font-size: rem(20px); } }
  &__action {
    width: $b;
    padding: 20px;
    border: 1px solid $border-color;
    text-align: center;
    @include border-radius(0 8px 8px 0);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @include media(767px) {
      width: 100%;
      border: none; } }
  .price {
    font-weight: 500;
    font-size: rem(24px);
    line-height: 1; } }
.features-list {
  li {
    font-size: rem(14px);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    &::before {
      content: "\f058";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      color: $success;
      margin-right: 5px; } } }

.hotel-search-form {
  .input-group {
    border: 1px solid $border-color;
    @extend %bs-5; }
  .select2-basic ~ .select2-container {
    width: auto !important;
    max-width: auto !important;
    flex: 1 1 auto; }
  .select2-container--default .select2-selection--single {
    border: none;
    .select2-selection__rendered {
      padding: 0; } }
  .input-group-text {
    background-color: #fff;
    border: none;
    i {
      font-size: rem(22px); } }
  .form--control {
    border: none;
    &:focus {
      box-shadow: none; } } }
/* search result section css end */
