/* hero section css start */
.hero {
  padding-top: rem(100px);
  padding-bottom: rem(100px);
  position: relative;
  z-index: 1;
  @include media(767px) {
    padding-top: rem(70px);
    padding-bottom: rem(80px); }
  &::before {
    @include positionTwo;
    background-color: $base-color-two;
    opacity: 0.55;
    mix-blend-mode: multiply;
    z-index: -1; }
  &__title {
    font-size: rem(48px);
    @include media(991px) {
      font-size: rem(42px); }
    @include media(575px) {
      font-size: rem(36px); } }
  &__description {
    font-size: rem(18px);
    @include media(991px) {
      font-size: rem(16px); } } }
.hero-search-area {
  padding: rem(15px) rem(30px);
  background-color: #fff;
  @include media(1199px) {
    padding: rem(10px) rem(15px); } }
.hero-search-form {
  .input-group {
    @include media(991px) {
      border: 1px solid $border-color;
      padding: 0 10px;
      @include border-radius(5px); } }
  .input-group-text {
    background-color: transparent;
    border: none;
    text-align: left;
    padding: 0;
    i {
      font-size: rem(26px);
      color: $heading-color;
      @include media(1199px) {
        font-size: rem(20px); } } }
  .select2-basic ~ .select2-container {
    width: auto !important;
    max-width: auto !important;
    flex: 1 1 auto; }
  .select2-container--default .select2-selection--single {
    border: none;
    .select2-selection__rendered {
      color: $heading-color;
      font-weight: 500;
      font-size: rem(16px);
      padding: 0 rem(10px);
      @include media(1199px) {
        font-size: rem(14px); } } }
  .form--control {
    border: none;
    color: $heading-color;
    font-weight: 500;
    font-size: rem(16px);
    padding: rem(10px);
    @include media(1199px) {
      font-size: rem(14px); }
    &:focus {
      box-shadow: none; } }
  button {
    @include media(991px) {
      width: 100%; } }
  label {
    @include media(991px) {
      margin-bottom: 0; } } }
/* hero section css end */

/* inner hero section start */
.inner-hero {
  padding-top: rem(80px);
  padding-bottom: rem(80px);
  position: relative;
  z-index: 1;
  &::before {
    @include positionTwo;
    background-color: $base-color-two;
    opacity: 0.65;
    z-index: -1; } }
/* inner hero section end */
