h1 {
  font-size: rem(62px); }
h2 {
  font-size: rem(32px);
  @media (max-width: 991px) {
    font-size: rem(30px); }
  @media (max-width: 575px) {
    font-size: rem(28px); } }
h3 {
  font-size: rem(24px); }
h4 {
  font-size: rem(22px);
  @media (max-width: 767px) {
    font-size: rem(20px); } }
h5 {
  font-size: rem(20px);
  @media (max-width: 767px) {
    font-size: rem(18px); } }
h6 {
  font-size: rem(18px); }
h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font;
  color: $heading-color;
  font-weight: 600;
  margin: 0;
  line-height: 1.3;
  word-break: break-word; }
h1>a, h2>a, h3>a, h4>a, h5>a, h6>a {
  font-family: $heading-font;
  color: $heading-color;
  font-weight: 600;
  @include transition(all 0.3s);
  line-height: 1.3;
  word-break: break-word; }
p, li, span {
  margin: 0; }
a {
  text-decoration: none;
  display: inline-block;
  font-family: $para-font;
  font-weight: 400; }
a:hover {
  text-decoration: none; }

.fs--18px {
  font-size: rem(18px) !important; }
.fs--16px {
  font-size: rem(16px) !important; }
.fs--14px {
  font-size: rem(14px) !important; }
.fs--12px {
  font-size: rem(12px) !important; }

.h--font {
  font-family: $heading-font !important; }

.p--font {
  font-family: $para-font !important; }

.fw-medium {
  font-weight: 500 !important; }
