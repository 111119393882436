
$heading-color: #373e4a;
$para-color: #464646;

$base-color: #f05945;
$base-color-two: #002046;

// bg-color
$bg-one: #071e3e;
$bg-two: #02014a;

// border-color
$border-color: #c0cad5;

// color variable for Bootstrap
$primary: #7367f0;
$secondary: #868e96;
$success: #28c76f;
$danger: #ea5455;
$warning: #ff9f43;
$info: #1e9ff2;
$dark: #10163A;
$light: #eef4ff;
$muted: #cccccc;
