/* reset css start */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&family=Roboto:wght@400;500&display=swap");
html {
  scroll-behavior: smooth; }

body {
  font-family: "Roboto", sans-serif;
  color: #464646;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  font-weight: 400;
  position: relative;
  line-height: 1.7;
  background-color: #fff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  overflow-x: hidden; }
  body.page-trns-active {
    position: relative; }
    body.page-trns-active::after {
      position: absolute;
      content: "\f110";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      color: #fff;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      font-size: 4.5rem;
      z-index: 999;
      animation: spinRoll 1s infinite linear; }
    body.page-trns-active::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #002046;
      z-index: 99;
      opacity: 0.65; }

@-webkit-keyframes spinRoll {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes spinRoll {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-ms-keyframes spinRoll {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinRoll {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

section {
  background-color: #fff; }

img {
  max-width: 100%;
  height: auto;
  user-select: none; }

select {
  cursor: pointer; }

ul, ol {
  padding: 0;
  margin: 0;
  list-style: none; }

button {
  cursor: pointer; }

*:focus {
  outline: none; }

button {
  border: none; }

button:focus {
  outline: none; }

span {
  display: inline-block; }

a:hover {
  color: #f05945; }

/* reset css end */
/* global css strat */
.text--primary {
  color: #7367f0 !important; }

.text--secondary {
  color: #868e96 !important; }

.text--success {
  color: #28c76f !important; }

.text--danger {
  color: #ea5455 !important; }

.text--warning {
  color: #ff9f43 !important; }

.text--info {
  color: #1e9ff2 !important; }

.text--dark {
  color: #10163A !important; }

.text--muted {
  color: #cccccc !important; }

.text--base {
  color: #f05945 !important; }

.text--dark {
  color: #373e4a !important; }

/* background color css start */
.bg--primary {
  background-color: #7367f0 !important; }

.bg--secondary {
  background-color: #868e96 !important; }

.bg--success {
  background-color: #28c76f !important; }

.bg--danger {
  background-color: #ea5455 !important; }

.bg--warning {
  background-color: #ff9f43 !important; }

.bg--info {
  background-color: #1e9ff2 !important; }

.bg--dark {
  background-color: #10163A !important; }

.bg--light {
  background-color: #eef4ff !important; }

.bg--base {
  background-color: #f05945 !important; }

/* background color css end */
.mb-30 {
  margin-bottom: 30px; }

.mb-none-30 {
  margin-bottom: -30px; }

.pt-25 {
  padding-top: 25px; }

.pb-25 {
  padding-bottom: 25px; }

.pt-50 {
  padding-top: 50px; }

.pb-50 {
  padding-bottom: 50px; }

.pt-100 {
  padding-top: 100px; }
  @media (max-width: 767px) {
    .pt-100 {
      padding-top: 80px; } }

.pb-100 {
  padding-bottom: 100px; }
  @media (max-width: 767px) {
    .pb-100 {
      padding-bottom: 80px; } }

.pt-120 {
  padding-top: 120px; }

.pb-120 {
  padding-bottom: 120px; }

.bg_img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.section--bg {
  background-color: #f0f3fb; }

.section--bg2 {
  background-color: #002046; }

.section-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.dark-overlay {
  position: relative;
  z-index: 1; }
  .dark-overlay::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #002046;
    opacity: 0.4;
    z-index: -1; }

.opacity20 {
  opacity: 0.2; }

.opacity30 {
  opacity: 0.3; }

.opacity50 {
  opacity: 0.5; }

.bg--one {
  background-color: #071e3e; }

.slick-arrow {
  cursor: pointer; }

.z-index-2 {
  z-index: 2; }

.main-wrapper {
  position: relative;
  z-index: 1; }

.section-header {
  margin-bottom: 2.8125rem; }

.section-title {
  font-size: 2.625rem; }
  @media (max-width: 991px) {
    .section-title {
      font-size: 2.375rem; } }
  @media (max-width: 767px) {
    .section-title {
      font-size: 2.25rem; } }
  @media (max-width: 575px) {
    .section-title {
      font-size: 2rem; } }

.section-subtitle {
  font-family: "Playfair Display", serif;
  font-size: 1rem;
  font-weight: 500; }
  .section-subtitle.border-left {
    padding-left: 2.1875rem;
    position: relative;
    z-index: 1; }
    .section-subtitle.border-left::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 25px;
      height: 2px;
      background-color: #f05945;
      margin-top: -1px; }

a.text-white:hover {
  color: #f05945 !important; }

.text--link {
  text-decoration: underline; }
  .text--link:hover {
    text-decoration: underline; }

.has--link {
  position: relative; }
  .has--link .item--link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.custom--dropdown .dropdown-toggle.no-arrow::after {
  display: none; }

.custom--dropdown .dropdown-toggle::after {
  content: "\f107";
  border: none;
  font-family: 'Line Awesome Free';
  font-weight: 900;
  -webkit-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px); }

.custom--dropdown .dropdown-menu {
  border-color: #e5e5e5;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); }
  .custom--dropdown .dropdown-menu li {
    border-bottom: 1px dashed #e5e5e5; }
    .custom--dropdown .dropdown-menu li:last-child {
      border-bottom: none; }
    .custom--dropdown .dropdown-menu li .dropdown-item {
      color: #464646;
      font-size: 0.875rem; }
      .custom--dropdown .dropdown-menu li .dropdown-item:hover {
        color: #f05945;
        background-color: rgba(240, 89, 69, 0.05); }

.custom--accordion .accordion-item + .accordion-item {
  margin-top: 1.25rem; }

.custom--accordion .accordion-item {
  border: 1px solid rgba(240, 89, 69, 0.5);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }
  .custom--accordion .accordion-item:first-child .accordion-button {
    border-top: none; }
  .custom--accordion .accordion-item:last-child .accordion-button {
    border-bottom: none; }

.custom--accordion .accordion-button {
  padding: 1.25rem 1.5625rem;
  background-color: rgba(240, 89, 69, 0.05);
  font-size: 1.125rem;
  position: relative;
  text-align: left; }
  .custom--accordion .accordion-button::after {
    position: absolute;
    top: 1.25rem;
    right: 0.8125rem;
    font-size: 1.0625rem;
    content: '\f107';
    font-family: 'Line Awesome Free';
    font-weight: 900;
    background-image: none;
    color: #000; }
  .custom--accordion .accordion-button:not(.collapsed) {
    background-color: #f05945;
    color: #fff; }
    .custom--accordion .accordion-button:not(.collapsed)::after {
      color: #fff; }
  .custom--accordion .accordion-button:focus {
    box-shadow: none;
    outline: none;
    border-color: transparent; }

.custom--accordion .accordion-body {
  padding: 1.25rem 1.5625rem; }

.page-breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 0.9375rem; }
  .page-breadcrumb li {
    color: rgba(255, 255, 255, 0.8);
    text-transform: capitalize; }
    .page-breadcrumb li::after {
      content: '-';
      color: #ffffff;
      margin: 0 0.3125rem; }
    .page-breadcrumb li:first-child::before {
      content: "\f015";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: #f05945;
      margin-right: 0.375rem; }
    .page-breadcrumb li:last-child::after {
      display: none; }
    .page-breadcrumb li a {
      color: #ffffff;
      text-transform: capitalize; }
      .page-breadcrumb li a:hover {
        color: #f05945; }

.cmn-list li + li {
  margin-top: 0.9375rem; }

.cmn-list li {
  position: relative;
  padding-left: 2.1875rem; }
  .cmn-list li::before {
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Line Awesome Free';
    font-weight: 900;
    content: "\f058";
    font-size: 1.75rem;
    color: #f05945;
    margin-right: 0.5rem;
    line-height: 1; }

.cmn-list-two li {
  padding: 0.375rem 0.9375rem;
  font-size: 0.875rem; }
  .cmn-list-two li:nth-child(even) {
    background-color: #EBF5F5; }

.number-list {
  list-style: decimal;
  padding-left: 1.125rem; }
  .number-list li + li {
    margin-top: 0.625rem; }

.disc-list li + li {
  margin-top: 0.625rem; }

.disc-list li {
  position: relative;
  padding-left: 0.9375rem; }
  .disc-list li::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 0.375rem;
    height: 0.375rem;
    margin-top: -0.1875rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: #bdbdbd; }

.square-list li + li {
  margin-top: 10px; }

.square-list li {
  padding-left: 25px;
  position: relative; }
  .square-list li::before {
    position: absolute;
    content: '';
    top: 5px;
    left: 0;
    width: 14px;
    height: 14px;
    background-color: #e6e6e6; }
  .square-list li::after {
    position: absolute;
    content: '';
    top: 9px;
    left: 4px;
    width: 14px;
    height: 14px;
    background-color: rgba(240, 89, 69, 0.45); }

.caption-list li {
  display: flex;
  flex-wrap: wrap;
  padding: 0.625rem 0;
  font-size: 0.9375rem;
  border-bottom: 1px dashed #c0cad5; }
  .caption-list li:first-child {
    padding-top: 0; }
  .caption-list li:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .caption-list li .caption {
    width: 30%;
    font-family: "Playfair Display", serif;
    font-weight: 700;
    font-size: 0.875rem;
    position: relative; }
    @media (max-width: 480px) {
      .caption-list li .caption {
        width: 35%; } }
    .caption-list li .caption::after {
      position: absolute;
      content: ':';
      top: 0;
      right: 0; }
  .caption-list li .value {
    width: 70%;
    padding-left: 0.9375rem; }
    @media (max-width: 480px) {
      .caption-list li .value {
        width: 65%; } }

.caption-list-two {
  padding: 0.625rem 0.9375rem;
  background-color: rgba(240, 89, 69, 0.1);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }
  .caption-list-two li {
    font-family: "Playfair Display", serif;
    font-weight: 500;
    color: #373e4a;
    font-size: 0.875rem;
    padding: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px dashed #a1b0c1; }
    .caption-list-two li:first-child {
      padding-top: 0; }
    .caption-list-two li:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    .caption-list-two li .caption {
      width: 20%;
      position: relative;
      font-weight: 700;
      padding-right: 10px; }
      .caption-list-two li .caption::after {
        position: absolute;
        content: ':';
        top: 0;
        right: 0; }
    .caption-list-two li .value {
      width: 80%;
      padding-left: 20px; }

body, .btn--base.btn--custom, .custom--checkbox label::before, .header .main-menu li.menu_has_children > a::before, .header .main-menu li .sub-menu, .header .main-menu li .sub-menu li a, .header .main-menu li .sub-menu li a::before, .location-slider .location-card, .location-slider .slick-arrow, .property-slider .slick-dots li button, .property-card__thumb img, .best-trip-slider .slick-arrow, .d-widget, .d-widget__icon, .action-sidebar, .user-menu li a, .hotel-details-thumb .full-view, .social-media-list li a, .google-map-section {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

.location-slider .slick-arrow:hover, .best-trip-slider .slick-arrow:hover, .d-widget:hover .d-widget__icon, .user-menu li.active a, .user-menu li.active:hover a, .social-media-list li a:hover {
  background-color: #f05945; }

.work-card__number, .user-widget, .book-widget, .social-media-list li a {
  background-color: #002046; }

.location-card .location-badge i, .testimonial-item .content::before, .d-widget__icon, .user-info-list li i, .user-menu li:hover a, .footer-menu-list li a:hover, .footer-contact-list > li .content a:hover, .social-media-list li a:hover, .contact-card__header .icon i, .contact-card a:hover {
  color: #f05945; }

.location-slider .slick-arrow, .d-widget__icon, .user-widget .thumb, .user-menu li.active a, .user-menu li:hover a, .hotel-search-form .input-group, .hotel-details-thumb-slider .slick-arrow, .reserve-widget .top, .social-media-list li a {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }

.table-hotel .thumb, .table-hotel .thumb img, .best-trip-card .thumb, .d-widget, .user-widget, .user-menu-widget, .hotel-card, .hotel-details-map, .reserve-widget, .book-widget, .contact-card, .contact-form {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px; }

.object-fit--cover {
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center; }

.pagination {
  margin: -0.3125rem -0.4375rem;
  flex-wrap: wrap; }
  .pagination .page-item {
    margin: 0.3125rem 0.4375rem; }
    .pagination .page-item.active .page-link {
      background-color: #f05945;
      color: #fff; }
    .pagination .page-item .page-link {
      width: 2.8125rem;
      height: 2.8125rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      border: 1px solid rgba(240, 89, 69, 0.25);
      color: #464646; }
      .pagination .page-item .page-link:hover {
        background-color: #f05945;
        border-color: #f05945;
        color: #fff; }

.pagination-md .page-item .page-link {
  width: 2.5rem;
  height: 2.5rem; }

.pagination-sm .page-item .page-link {
  width: 2.1875rem;
  height: 2.1875rem;
  font-size: 0.875rem; }

.shake {
  animation: shake 0.5s 1 linear; }

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); } }

@-moz-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); } }

@-ms-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); } }

@keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-moz-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-ms-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

/* global css end */
/* preloader css start */
@-webkit-keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-webkit-keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0); }
  30% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0); }
  30% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0); }
  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); } }

/* Styles */
.preloader {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: white;
  text-align: center; }
  .preloader .preloader-container {
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: auto;
    position: absolute;
    top: 44%;
    left: 0;
    position: relative; }
    .preloader .preloader-container .animated-preloader {
      display: inline-block;
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      background: #f05945;
      border-radius: 50em; }
      .preloader .preloader-container .animated-preloader:after {
        content: '';
        display: inline-block;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50em;
        background: white;
        -webkit-animation: preloader-inside-white 1s ease-in-out infinite;
        -ms-animation: preloader-inside-white 1s ease-in-out infinite;
        animation: preloader-inside-white 1s ease-in-out infinite; }
      .preloader .preloader-container .animated-preloader:before {
        content: '';
        display: inline-block;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        border-radius: 50em;
        background: #f05945;
        -webkit-animation: preloader-inside-red 1s ease-in-out infinite;
        -ms-animation: preloader-inside-red 1s ease-in-out infinite;
        animation: preloader-inside-red 1s ease-in-out infinite; }

/* preloader css end */
h1 {
  font-size: 3.875rem; }

h2 {
  font-size: 2rem; }
  @media (max-width: 991px) {
    h2 {
      font-size: 1.875rem; } }
  @media (max-width: 575px) {
    h2 {
      font-size: 1.75rem; } }

h3 {
  font-size: 1.5rem; }

h4 {
  font-size: 1.375rem; }
  @media (max-width: 767px) {
    h4 {
      font-size: 1.25rem; } }

h5 {
  font-size: 1.25rem; }
  @media (max-width: 767px) {
    h5 {
      font-size: 1.125rem; } }

h6 {
  font-size: 1.125rem; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", serif;
  color: #373e4a;
  font-weight: 600;
  margin: 0;
  line-height: 1.3;
  word-break: break-word; }

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  font-family: "Playfair Display", serif;
  color: #373e4a;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 1.3;
  word-break: break-word; }

p, li, span {
  margin: 0; }

a {
  text-decoration: none;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 400; }

a:hover {
  text-decoration: none; }

.fs--18px {
  font-size: 1.125rem !important; }

.fs--16px {
  font-size: 1rem !important; }

.fs--14px {
  font-size: 0.875rem !important; }

.fs--12px {
  font-size: 0.75rem !important; }

.h--font {
  font-family: "Playfair Display", serif !important; }

.p--font {
  font-family: "Roboto", sans-serif !important; }

.fw-medium {
  font-weight: 500 !important; }

/* button css start */
button:focus {
  outline: none; }

[class*="btn--"]:not(.btn--link):not(.btn--light) {
  color: #fff; }

.btn {
  padding: 0.75rem 1.875rem; }

.btn--primary {
  background-color: #7367f0; }
  .btn--primary:hover {
    background-color: #5e50ee; }

.btn--secondary {
  background-color: #868e96; }
  .btn--secondary:hover {
    background-color: #78818a; }

.btn--success {
  background-color: #28c76f; }
  .btn--success:hover {
    background-color: #24b263; }

.btn--danger {
  background-color: #ea5455; }
  .btn--danger:hover {
    background-color: #e73d3e; }

.btn--warning {
  background-color: #ff9f43; }
  .btn--warning:hover {
    background-color: #ff922a; }

.btn--info {
  background-color: #1e9ff2; }
  .btn--info:hover {
    background-color: #0d93e9; }

.btn--light {
  background-color: #eef4ff; }
  .btn--light:hover {
    background-color: #d5e4ff; }

.btn--dark {
  background-color: #10163A;
  color: #fff; }
  .btn--dark:hover {
    background-color: #0a0e26;
    color: #fff; }

.btn--link {
  color: #7367f0; }

.btn--base {
  background-color: #f05945;
  color: #fff; }
  .btn--base:hover {
    background-color: #ee442d;
    color: #fff; }
  .btn--base.btn--custom {
    outline: 2px solid rgba(255, 255, 255, 0.7);
    outline-offset: -6px; }
    .btn--base.btn--custom:hover {
      outline-offset: 0;
      outline: 2px solid rgba(255, 255, 255, 0); }

.text-btn {
  padding: 0;
  color: #464646;
  background-color: transparent; }

.btn-outline--primary {
  color: #7367f0;
  border-color: #7367f0; }
  .btn-outline--primary:hover {
    background-color: #7367f0;
    color: #ffffff; }

.btn-outline--secondary {
  color: #868e96;
  border-color: #868e96; }
  .btn-outline--secondary:hover {
    background-color: #868e96;
    color: #ffffff; }

.btn-outline--success {
  color: #28c76f;
  border-color: #28c76f; }
  .btn-outline--success:hover {
    background-color: #28c76f;
    color: #ffffff; }

.btn-outline--danger {
  color: #ea5455;
  border-color: #ea5455; }
  .btn-outline--danger:hover {
    background-color: #ea5455;
    color: #ffffff; }

.btn-outline--warning {
  color: #ff9f43;
  border-color: #ff9f43; }
  .btn-outline--warning:hover {
    background-color: #ff9f43;
    color: #ffffff; }

.btn-outline--info {
  color: #1e9ff2;
  border-color: #1e9ff2; }
  .btn-outline--info:hover {
    background-color: #1e9ff2;
    color: #ffffff; }

.btn-outline--light {
  color: #eef4ff;
  border-color: #eef4ff; }
  .btn-outline--light:hover {
    background-color: #eef4ff;
    color: #ffffff; }

.btn-outline--dark {
  color: #10163A;
  border-color: #10163A; }
  .btn-outline--dark:hover {
    background-color: #10163A;
    color: #ffffff; }

.btn-outline--base {
  color: #f05945;
  border: 1px solid #f05945; }
  .btn-outline--base:hover {
    background-color: #f05945;
    color: #fff; }

.btn-shadow--primary {
  box-shadow: 0 0 6px 1px rgba(115, 103, 240, 0.35); }

.btn-shadow--secondary {
  box-shadow: 0 0 6px 1px rgba(134, 142, 150, 0.35); }

.btn-shadow--success {
  box-shadow: 0 0 6px 1px rgba(40, 199, 111, 0.35); }

.btn-shadow--danger {
  box-shadow: 0 0 6px 1px rgba(234, 84, 85, 0.35); }

.btn-shadow--warning {
  box-shadow: 0 0 6px 1px rgba(255, 159, 67, 0.35); }

.btn-shadow--info {
  box-shadow: 0 0 6px 1px rgba(30, 159, 242, 0.35); }

.btn-shadow--light {
  box-shadow: 0 0 6px 1px rgba(238, 244, 255, 0.35); }

.btn-shadow--dark {
  box-shadow: 0 0 6px 1px rgba(16, 22, 58, 0.35); }

.btn-shadow--base {
  box-shadow: 0 0 6px 1px rgba(240, 89, 69, 0.35); }

.btn--capsule {
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px; }

.icon-btn {
  width: 1.5625rem;
  height: 1.5625rem;
  background-color: #f05945;
  color: #fff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center; }
  .icon-btn:hover {
    color: #fff; }

.btn--group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  .btn--group *[class*="btn"] {
    margin: 0.3125rem 0.625rem;
    align-items: center; }
    .btn--group *[class*="btn"].d-flex {
      padding: 0.5rem 2.1875rem; }
  .btn--group.style--two {
    margin-left: -0.3125rem;
    margin-right: -0.3125rem; }
    .btn--group.style--two *[class*="btn"] {
      margin: 0.1875rem 0.3125rem; }

[class*="btn"].btn-md {
  padding: 0.625rem 1.25rem; }

[class*="btn"].btn-sm {
  padding: 0.375rem 0.625rem; }

/* button css end */
.badge--primary {
  background-color: rgba(115, 103, 240, 0.15);
  border: 1px solid #7367f0;
  color: #7367f0; }

.badge--secondary {
  background-color: rgba(134, 142, 150, 0.15);
  border: 1px solid #868e96;
  color: #868e96; }

.badge--success {
  background-color: rgba(40, 199, 111, 0.15);
  border: 1px solid #28c76f;
  color: #28c76f; }

.badge--danger {
  background-color: rgba(234, 84, 85, 0.15);
  border: 1px solid #ea5455;
  color: #ea5455; }

.badge--warning {
  background-color: rgba(255, 159, 67, 0.15);
  border: 1px solid #ff9f43;
  color: #ff9f43; }

.badge--info {
  background-color: rgba(30, 159, 242, 0.15);
  border: 1px solid #1e9ff2;
  color: #1e9ff2; }

.badge--light {
  background-color: rgba(238, 244, 255, 0.15);
  border: 1px solid #eef4ff;
  color: #eef4ff; }

.badge--dark {
  background-color: rgba(16, 22, 58, 0.15);
  border: 1px solid #10163A;
  color: #10163A; }

.badge--base {
  background-color: rgba(240, 89, 69, 0.15);
  border: 1px solid #f05945;
  color: #f05945; }

/* table css start */
.custom--table {
  background-color: #fff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px; }
  .custom--table.white-space-nowrap th {
    white-space: nowrap; }
  .custom--table thead {
    background-color: #f0f3fb; }
    .custom--table thead th {
      border-top: none;
      padding: 0.625rem 1.25rem;
      color: #606060;
      background-color: transparent;
      border: none;
      font-size: 0.8125rem;
      text-transform: uppercase;
      font-weight: 500;
      text-align: center; }
      .custom--table thead th:first-child {
        text-align: left; }
      .custom--table thead th:last-child {
        text-align: right; }
  .custom--table tbody td {
    border-top: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    padding: 0.9375rem 1.25rem;
    color: #464646;
    vertical-align: middle;
    font-size: 0.9375rem;
    text-align: center; }
    .custom--table tbody td:first-child {
      text-align: left; }
    .custom--table tbody td:last-child {
      text-align: right; }
  .custom--table tbody tr:nth-child(even) {
    background-color: #afb1b50d; }
  .custom--table tbody tr:last-child td {
    border-bottom: none; }

@media (max-width: 991px) {
  .table-hotel {
    justify-content: flex-end; } }

.table-hotel .thumb {
  width: 65px;
  height: 45px;
  overflow: hidden;
  padding: 3px;
  border: 1px solid #c0cad5;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15); }
  .table-hotel .thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center; }

.table-hotel .content {
  padding-left: 0.9375rem; }

[data-label] {
  position: relative; }
  [data-label]::before {
    position: absolute;
    content: attr(data-label);
    font-weight: 700;
    color: #000000;
    top: 0;
    left: 0;
    padding: 0.8125rem 0.9375rem;
    display: none;
    font-size: 0.75rem; }

@media (max-width: 991px) {
  .table-responsive--md thead {
    display: none; }
  .table-responsive--md tbody tr:nth-child(odd) {
    background-color: aliceblue; }
  .table-responsive--md tr th, .table-responsive--md tr td {
    display: block;
    padding-left: 45% !important;
    text-align: right !important;
    border-top: 1px solid rgba(0, 0, 0, 0.08) !important; }
    .table-responsive--md tr th:first-child, .table-responsive--md tr td:first-child {
      border-top: none !important; }
  .table-responsive--md [data-label]::before {
    display: block; } }

@media (max-width: 767px) {
  .table-responsive--sm thead {
    display: none; }
  .table-responsive--sm tbody tr:nth-child(odd) {
    background-color: aliceblue; }
  .table-responsive--sm tr th, .table-responsive--sm tr td {
    display: block;
    padding-left: 45% !important;
    text-align: right !important;
    border-top: 1px solid rgba(0, 0, 0, 0.08) !important; }
    .table-responsive--sm tr th:first-child, .table-responsive--sm tr td:first-child {
      border-top: none !important; }
  .table-responsive--sm [data-label]::before {
    display: block; } }

@media (max-width: 1199px) {
  *[class*="table-responsive--"].data-label--none tr th, *[class*="table-responsive--"].data-label--none tr td {
    padding-left: .75rem; } }

/* table css end */
/* form css start */
.form-group {
  margin-bottom: 0.9375rem; }

.form--control {
  padding: 0.625rem 1.25rem;
  border: 1px solid #f05945;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  color: #000;
  height: 3.125rem; }
  .form--control:focus {
    background-color: #fff;
    border-color: #f05945 !important;
    box-shadow: 0 0 5px rgba(240, 89, 69, 0.35);
    color: #000; }
  .form--control:placeholder-shown {
    border-color: #b1bdcb;
    color: #464646; }
  .form--control[readonly] {
    background-color: #fff; }
  .form--control.style--two {
    border-width: 0 0 1px 0;
    padding: 0.625rem 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    font-family: "Playfair Display", serif;
    border-bottom-color: #999999; }
    .form--control.style--two:focus {
      box-shadow: none; }
  .form--control.form-control-sm {
    height: 35px; }

.select {
  padding: 0.625rem 1.25rem;
  width: 100%;
  border: 1px solid #b1bdcb;
  cursor: pointer;
  color: #464646;
  background-color: #fff;
  height: 3.125rem;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px; }
  .select option {
    padding: 0.625rem 0;
    display: block;
    border-top: 1px solid #e5e5e5; }
  .select.style--trans {
    background-color: transparent;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.5); }
    .select.style--trans option {
      color: #363636; }
  .select.select-sm {
    height: 2.1875rem;
    font-size: 0.875rem;
    padding: 0.3125rem; }

textarea {
  min-height: 9.375rem !important;
  resize: none;
  width: 100%; }

label {
  color: #535353;
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
  font-weight: 500; }

.input-group > .form--control,
.input-group > .select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0; }

.input-group select {
  background-color: transparent;
  border: none; }

.custom-radio {
  position: relative;
  padding-left: 0; }
  .custom-radio input[type=radio] {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    cursor: pointer; }
    .custom-radio input[type=radio]:checked ~ label::before {
      border-width: 2px;
      border-color: #f05945; }
    .custom-radio input[type=radio]:checked ~ label::after {
      opacity: 1; }
  .custom-radio label {
    margin-bottom: 0;
    position: relative;
    padding-left: 20px;
    font-size: 0.9375rem; }
    .custom-radio label::before {
      position: absolute;
      content: '';
      top: 1px;
      left: 0;
      width: 15px;
      height: 15px;
      border: 1px solid #888888;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
    .custom-radio label::after {
      position: absolute;
      content: '';
      top: 5px;
      left: 4px;
      width: 7px;
      height: 7px;
      background-color: #f05945;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      opacity: 0;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
  .custom-radio.style--two label::before {
    top: 5px; }
  .custom-radio.style--two label::after {
    top: 9px; }

.custom--checkbox {
  padding-left: 1.5625rem; }
  .custom--checkbox input {
    display: none; }
    .custom--checkbox input:checked ~ label::before {
      content: "\f14a";
      color: #f05945; }
  .custom--checkbox label {
    position: relative;
    font-size: 0.9375rem;
    font-weight: 400;
    cursor: pointer; }
    .custom--checkbox label::before {
      position: absolute;
      content: "\f04d";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      top: 3px;
      left: -1.5625rem;
      font-size: 1.25rem;
      line-height: 1; }

.wrong-info .form--control {
  border-color: #ea5455 !important;
  box-shadow: 0 0 6px 1px rgba(234, 84, 85, 0.3) !important; }

.select2-basic ~ .select2-container {
  display: block;
  width: 100% !important;
  max-width: 100% !important;
  height: 50px; }

.select2-container .select2-dropdown {
  border: 1px dashed #b1bdcb;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); }

.select2-container .selection, .select2-container .select2-selection--single, .select2-container .select2-selection__rendered {
  display: block; }

.select2-container .select2-selection--single {
  height: 50px;
  border-color: #b1bdcb; }
  .select2-container .select2-selection--single .select2-selection__rendered {
    line-height: 50px;
    padding-left: 1.25rem; }
  .select2-container .select2-selection--single .select2-selection__arrow {
    height: 50px; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #f05945; }

.select2-dropdown ::-webkit-scrollbar {
  width: 0.5rem; }

.select2-dropdown ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.select2-dropdown ::-webkit-scrollbar-thumb {
  background-color: #002046; }

/* form css end*/
/* card css start */
.custom--card {
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #c0cad5;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }
  .custom--card .card-header {
    background-color: #fff;
    padding: 0.625rem 0.9375rem; }
  .custom--card .card-body {
    padding: 0.9375rem; }

/* card css end */
/* modal css start */
.modal {
  z-index: 999999; }

/* modal css end */
/* header start */
.header {
  background-color: #fff; }
  .header.menu-fixed .header__top {
    display: none; }
  .header.menu-fixed .header__bottom {
    background-color: #002046; }
  .header__bottom {
    border-bottom: none;
    padding: 0.3125rem 0; }
    @media (max-width: 1199px) {
      .header__bottom {
        padding: 0.625rem 0; } }
  .header .site-logo img {
    max-width: 10.9375rem;
    max-height: 3.75rem; }
    @media (max-width: 1199px) {
      .header .site-logo img {
        max-width: 9.375rem; } }
  .header .main-menu {
    margin-left: 4.375rem; }
    @media (max-width: 1199px) {
      .header .main-menu {
        margin-left: 0;
        padding: 0.9375rem 0; } }
    .header .main-menu li {
      position: relative; }
      @media (max-width: 1199px) {
        .header .main-menu li {
          border-bottom: 1px solid rgba(255, 255, 255, 0.15); } }
      .header .main-menu li:last-child a {
        padding-right: 0; }
      .header .main-menu li.menu_has_children {
        position: relative; }
        .header .main-menu li.menu_has_children.open .sub-menu {
          display: block; }
        .header .main-menu li.menu_has_children > a {
          padding-right: 1.5625rem; }
          @media (max-width: 1199px) {
            .header .main-menu li.menu_has_children > a {
              display: block; } }
          .header .main-menu li.menu_has_children > a::before {
            position: absolute;
            content: "\f067";
            font-family: "Line Awesome Free";
            font-weight: 900;
            top: 30px;
            right: 0;
            color: #ffffff; }
            @media (max-width: 1199px) {
              .header .main-menu li.menu_has_children > a::before {
                display: block;
                top: 0.5625rem; } }
        .header .main-menu li.menu_has_children:hover > a::before {
          content: "\f068";
          color: #f05945; }
      .header .main-menu li a {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0.9375rem 0.9375rem 0.9375rem 0;
        font-size: 0.9375rem;
        color: #373e4a; }
        @media (max-width: 1199px) {
          .header .main-menu li a {
            color: #ffffff;
            padding: 0.5rem 0;
            display: block; } }
        .header .main-menu li a:hover, .header .main-menu li a:focus {
          color: #f05945; }
      .header .main-menu li .sub-menu {
        position: absolute;
        width: 220px;
        top: 105%;
        left: 0;
        z-index: 9999;
        background-color: #fff;
        padding: 0.625rem 0;
        -webkit-box-shadow: 0px 18px 54px -8px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 5px 25px 2px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        opacity: 0;
        visibility: hidden;
        border: 2px solid #e5e5e5; }
        @media (max-width: 1199px) {
          .header .main-menu li .sub-menu {
            opacity: 1;
            visibility: visible;
            display: none;
            position: static;
            -webkit-transition: none;
            -o-transition: none;
            transition: none;
            width: 100%;
            background-color: #002046;
            border: none; } }
        .header .main-menu li .sub-menu::before {
          position: absolute;
          content: '';
          top: -19px;
          left: 20px;
          border-width: 10px 10px 10px 10px;
          border-style: solid;
          border-color: transparent transparent #fff transparent; }
          @media (max-width: 1199px) {
            .header .main-menu li .sub-menu::before {
              display: none; } }
        .header .main-menu li .sub-menu li {
          border-bottom: 1px dashed #e5e5e5; }
          @media (max-width: 1199px) {
            .header .main-menu li .sub-menu li {
              border-color: rgba(255, 255, 255, 0.15); } }
          .header .main-menu li .sub-menu li:last-child {
            border-bottom: none; }
          .header .main-menu li .sub-menu li a {
            padding: 0.5rem 1.5625rem;
            display: block;
            color: #464646;
            position: relative;
            font-size: 0.9375rem;
            text-transform: capitalize; }
            @media (max-width: 1199px) {
              .header .main-menu li .sub-menu li a {
                color: #fff; } }
            .header .main-menu li .sub-menu li a::before {
              position: absolute;
              content: '';
              top: 0;
              left: -0.25rem;
              width: 0.25rem;
              height: 100%;
              background-color: #f05945;
              opacity: 0; }
            .header .main-menu li .sub-menu li a:hover {
              background-color: rgba(240, 89, 69, 0.05);
              color: #f05945; }
              .header .main-menu li .sub-menu li a:hover::before {
                opacity: 1;
                left: 0; }
        .header .main-menu li .sub-menu li + li {
          margin-left: 0; }
      .header .main-menu li:hover .sub-menu {
        top: 100%;
        opacity: 1;
        visibility: visible; }
    .header .main-menu li + li {
      margin-left: 1.25rem; }
      @media (max-width: 1199px) {
        .header .main-menu li + li {
          margin-left: 0; } }
  .header .nav-right {
    padding-left: 3.125rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center; }
    @media (max-width: 1199px) {
      .header .nav-right {
        padding-left: 0; } }

.language-select {
  background-color: transparent;
  color: #373e4a;
  height: 45px;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px; }
  @media (max-width: 1199px) {
    .language-select {
      color: #fff; } }
  .language-select option {
    background-color: #002046;
    color: #fff; }

@media (max-width: 1199px) {
  .navbar-collapse {
    background-color: #071e3e;
    padding: 0 1.875rem 1.25rem 1.875rem; } }

@media (max-width: 767px) {
  .navbar-collapse {
    max-height: 20rem;
    overflow: auto; } }

.navbar-toggler {
  padding: 0;
  border: none; }
  .navbar-toggler:focus {
    outline: none;
    box-shadow: none; }

.menu-toggle {
  margin: 10px 0;
  position: relative;
  display: block;
  width: 2.1875rem;
  height: 1.25rem;
  cursor: pointer;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: #000;
  font-size: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer; }

.menu-toggle:before, .menu-toggle:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: currentColor;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: -webkit-transform 0.25s ease-in-out;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  -moz-transition: -webkit-transform 0.25s ease-in-out;
  -ms-transition: -webkit-transform 0.25s ease-in-out; }
  @media (max-width: 1199px) {
    .menu-toggle:before, .menu-toggle:after {
      background-color: #000; } }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

/* header end */
/* hero section css start */
.hero {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
  position: relative;
  z-index: 1; }
  @media (max-width: 767px) {
    .hero {
      padding-top: 4.375rem;
      padding-bottom: 5rem; } }
  .hero::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #002046;
    opacity: 0.55;
    mix-blend-mode: multiply;
    z-index: -1; }
  .hero__title {
    font-size: 3rem; }
    @media (max-width: 991px) {
      .hero__title {
        font-size: 2.625rem; } }
    @media (max-width: 575px) {
      .hero__title {
        font-size: 2.25rem; } }
  .hero__description {
    font-size: 1.125rem; }
    @media (max-width: 991px) {
      .hero__description {
        font-size: 1rem; } }

.hero-search-area {
  padding: 0.9375rem 1.875rem;
  background-color: #fff; }
  @media (max-width: 1199px) {
    .hero-search-area {
      padding: 0.625rem 0.9375rem; } }

@media (max-width: 991px) {
  .hero-search-form .input-group {
    border: 1px solid #c0cad5;
    padding: 0 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px; } }

.hero-search-form .input-group-text {
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 0; }
  .hero-search-form .input-group-text i {
    font-size: 1.625rem;
    color: #373e4a; }
    @media (max-width: 1199px) {
      .hero-search-form .input-group-text i {
        font-size: 1.25rem; } }

.hero-search-form .select2-basic ~ .select2-container {
  width: auto !important;
  max-width: auto !important;
  flex: 1 1 auto; }

.hero-search-form .select2-container--default .select2-selection--single {
  border: none; }
  .hero-search-form .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #373e4a;
    font-weight: 500;
    font-size: 1rem;
    padding: 0 0.625rem; }
    @media (max-width: 1199px) {
      .hero-search-form .select2-container--default .select2-selection--single .select2-selection__rendered {
        font-size: 0.875rem; } }

.hero-search-form .form--control {
  border: none;
  color: #373e4a;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.625rem; }
  @media (max-width: 1199px) {
    .hero-search-form .form--control {
      font-size: 0.875rem; } }
  .hero-search-form .form--control:focus {
    box-shadow: none; }

@media (max-width: 991px) {
  .hero-search-form button {
    width: 100%; } }

@media (max-width: 991px) {
  .hero-search-form label {
    margin-bottom: 0; } }

/* hero section css end */
/* inner hero section start */
.inner-hero {
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
  z-index: 1; }
  .inner-hero::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #002046;
    opacity: 0.65;
    z-index: -1; }

/* inner hero section end */
/* section css start */
.location-section {
  position: relative;
  z-index: 1;
  background-position-y: top;
  background-position-x: -300px; }
  .location-section::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.7;
    z-index: -1; }

.location-card {
  position: relative; }
  .location-card::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ff7e5f;
    background: -webkit-linear-gradient(to top, rgba(0, 32, 70, 0.85), rgba(0, 32, 70, 0.1));
    background: linear-gradient(to top, rgba(0, 32, 70, 0.85), rgba(0, 32, 70, 0.1)); }
  .location-card .overlay-content {
    position: absolute;
    padding: 1.25rem;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-flow: wrap;
    align-content: space-between; }
    .location-card .overlay-content .top, .location-card .overlay-content .bottom {
      width: 100%; }

.location-slider .slick-list {
  margin: 0 -0.625rem; }

.location-slider .single-slide {
  margin: 0 0.625rem; }

.location-slider .location-card {
  height: 375px; }
  .location-slider .location-card:hover {
    -webkit-transform: scale(1.03, 1.03);
    -ms-transform: scale(1.03, 1.03);
    transform: scale(1.03, 1.03); }
  .location-slider .location-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center; }

.location-slider .slick-arrow {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #fff;
  color: #464646;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  top: 45%;
  z-index: 1; }
  .location-slider .slick-arrow:hover {
    color: #ffff; }
  .location-slider .slick-arrow.prev {
    left: 15px; }
  .location-slider .slick-arrow.next {
    right: 15px; }

.location-card {
  overflow: hidden; }
  .location-card .location-name {
    margin-bottom: 0.3125rem; }
  .location-card .location-badge {
    background-color: rgba(255, 255, 255, 0.25);
    color: #fff; }

/* section css end */
/* property section css start */
.property-section {
  position: relative;
  z-index: 1; }
  .property-section .bg-el {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0.15;
    z-index: -1; }
    .property-section .bg-el img {
      max-height: 300px; }
  .property-section .bg-el2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    opacity: 0.15;
    text-align: right;
    z-index: -1; }
    .property-section .bg-el2 img {
      max-height: 300px; }

.property-card {
  background-color: #fff;
  box-shadow: 0 3px 15px rgba(0, 32, 70, 0.1);
  overflow: hidden; }
  .property-card__content {
    padding: 1.25rem; }

.property-slider .slick-list {
  margin: -0.625rem; }

.property-slider .single-slide {
  margin: 0.625rem; }

.property-slider .slick-dots {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px; }
  .property-slider .slick-dots li {
    margin: 0 5px; }
    .property-slider .slick-dots li.slick-active button {
      background-color: #f05945;
      width: 25px; }
    .property-slider .slick-dots li button {
      font-size: 0;
      width: 15px;
      height: 6px;
      background-color: #ddd;
      border-radius: 999px;
      -webkit-border-radius: 999px;
      -moz-border-radius: 999px;
      -ms-border-radius: 999px;
      -o-border-radius: 999px; }

.property-card:hover .property-card__thumb img {
  -webkit-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05); }

.property-card__thumb {
  height: 200px;
  overflow: hidden; }
  .property-card__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center; }

/* property section css end */
/* best plan section css start */
.best-trip-section {
  background-position-y: 10%;
  position: relative;
  z-index: 1; }
  .best-trip-section::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.8;
    z-index: -1;
    display: none; }
    @media (max-width: 1199px) {
      .best-trip-section::before {
        display: block; } }

.best-trip-card {
  padding: 0.9375rem;
  background-color: #002046;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  color: #fff;
  position: relative;
  overflow: hidden; }
  .best-trip-card h6, .best-trip-card h5, .best-trip-card h4, .best-trip-card h3, .best-trip-card h2 {
    color: #fff; }
  .best-trip-card__badge {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 100px;
    height: 100px;
    background-color: #f05945;
    color: #fff;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    text-align: right;
    padding-right: 15px;
    padding-top: 10px; }
  .best-trip-card .thumb {
    height: 245px;
    overflow: hidden; }
    .best-trip-card .thumb img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -o-object-fit: cover;
      object-position: center;
      -o-object-position: center; }
  .best-trip-card .content {
    padding: 1.5625rem 0.625rem 0.625rem 0.625rem; }
    .best-trip-card .content .bottom {
      padding-top: 0.625rem;
      margin-top: 0.9375rem;
      border-top: 1px solid rgba(255, 255, 255, 0.15); }
  .best-trip-card .price {
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 500; }

.ratings i {
  color: #FAC42D; }

.best-trip-slider .slick-lsit {
  margin: 0 -0.9375rem; }

.best-trip-slider .single-slide {
  padding: 0 0.625rem; }

.best-trip-slider .slick-arrow {
  width: 45px;
  height: 45px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: absolute;
  top: 30%;
  z-index: 1; }
  .best-trip-slider .slick-arrow:hover {
    color: #fff; }
  .best-trip-slider .slick-arrow.prev {
    left: -10px; }
    @media (max-width: 1199px) {
      .best-trip-slider .slick-arrow.prev {
        left: 15px; } }
  .best-trip-slider .slick-arrow.next {
    right: -10px; }
    @media (max-width: 1199px) {
      .best-trip-slider .slick-arrow.next {
        right: 15px; } }

/* best plan section css end */
/* how work section css start */
.how-work-section {
  position: relative;
  z-index: 1; }
  .how-work-section::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -1;
    opacity: 0.85; }
  .how-work-section .left-el, .how-work-section .right-el {
    position: absolute;
    bottom: 0;
    z-index: -1; }
    .how-work-section .left-el img, .how-work-section .right-el img {
      max-height: 300px; }
      @media (max-width: 1399px) {
        .how-work-section .left-el img, .how-work-section .right-el img {
          max-width: 200px;
          opacity: 0.25; } }
  .how-work-section .left-el {
    left: 0; }
  .how-work-section .right-el {
    right: 0; }

.work-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .work-card__number {
    width: 2.1875rem;
    height: 2.1875rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.125rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin-right: 0.625rem; }
  @media (max-width: 1199px) {
    .work-card .title {
      font-size: 1.125rem; } }

/* how work section css end */
/* testimonial section css start */
.testimonial-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 40px 20px;
  z-index: 1; }
  @media (max-width: 1199px) {
    .testimonial-item {
      padding: 10px 20px; } }
  @media (max-width: 991px) {
    .testimonial-item {
      padding: 30px; } }
  .testimonial-item::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    background-color: transparent;
    border: 10px solid #f05945;
    z-index: -1; }
    @media (max-width: 991px) {
      .testimonial-item::after {
        width: 100%;
        border-width: 5px; } }
  .testimonial-item .thumb {
    width: 35%; }
    @media (max-width: 991px) {
      .testimonial-item .thumb {
        width: 100%; } }
  .testimonial-item .content {
    width: 65%;
    padding: 30px 30px 30px 150px;
    background-color: #fff;
    position: relative; }
    @media (max-width: 991px) {
      .testimonial-item .content {
        width: 100%;
        padding: 30px 30px 30px 70px; } }
    @media (max-width: 575px) {
      .testimonial-item .content {
        padding: 30px 0; } }
    .testimonial-item .content::before {
      position: absolute;
      content: "\f10d";
      top: 20px;
      left: 20px;
      font-size: 5.625rem;
      line-height: 1;
      font-family: 'Line Awesome Free';
      font-weight: 900; }
      @media (max-width: 991px) {
        .testimonial-item .content::before {
          font-size: 3rem;
          left: 0; } }
      @media (max-width: 575px) {
        .testimonial-item .content::before {
          display: none; } }
    .testimonial-item .content .testimonial-seech {
      font-size: 1.25rem;
      font-family: "Playfair Display", serif; }

.testimonial-slider .slick-arrow {
  width: 45px;
  height: 45px;
  background-color: #f05945;
  color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  z-index: 1; }
  @media (max-width: 991px) {
    .testimonial-slider .slick-arrow {
      top: auto;
      bottom: -20px; } }
  .testimonial-slider .slick-arrow.prev {
    right: -15px; }
    @media (max-width: 991px) {
      .testimonial-slider .slick-arrow.prev {
        right: 52%; } }
  .testimonial-slider .slick-arrow.next {
    right: -15px;
    top: 55%; }
    @media (max-width: 991px) {
      .testimonial-slider .slick-arrow.next {
        top: auto;
        right: 44%; } }

/* testimonial section css end */
/* video section css start */
.video--btn {
  width: 100px;
  height: 100px;
  border: 2px solid #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 3rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%; }

/* video section css end */
/* blog section css start */
.blog-card {
  overflow: hidden;
  position: relative;
  max-height: 350px; }
  .blog-card::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #002046;
    background: -webkit-linear-gradient(to top, rgba(0, 32, 70, 0.85), rgba(0, 32, 70, 0.1));
    background: linear-gradient(to top, rgba(0, 32, 70, 0.85), rgba(0, 32, 70, 0.1)); }
  .blog-card__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center; }
  .blog-card__content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1.875rem; }

/* blog section css end */
/* dashboard section css start */
.d-widget {
  padding: 0.9375rem;
  background-color: #fff;
  border: 1px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.05); }
  .d-widget:hover {
    box-shadow: 0 8px 45px rgba(0, 0, 0, 0.1); }
    .d-widget:hover .d-widget__icon {
      color: #fff; }
  .d-widget__icon {
    width: 3.75rem;
    height: 3.75rem;
    background-color: rgba(240, 89, 69, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem; }
  .d-widget__content {
    width: calc(100% - 3.75rem);
    padding-left: 1.25rem; }
    .d-widget__content .amount {
      font-size: 2rem; }

/* dashboard section css end */
/* sidebar css start */
.sidebar {
  padding-left: 30px; }
  @media (max-width: 991px) {
    .sidebar {
      padding-left: 0;
      margin-top: 50px; } }
  .sidebar .widget + .widget {
    margin-top: 50px; }
    @media (max-width: 767px) {
      .sidebar .widget + .widget {
        margin-top: 30px; } }
  .sidebar .widget {
    padding: 30px;
    border: 1px solid #e5e5e5;
    background-color: #fff; }
    .sidebar .widget .search-form {
      position: relative; }
      .sidebar .widget .search-form input {
        width: 100%;
        border: 1px solid #e5e5e5; }
      .sidebar .widget .search-form .search-btn {
        position: absolute;
        color: #f05945;
        background-color: transparent;
        top: 0;
        right: 0;
        font-size: 18px;
        color: #464646;
        width: 50px;
        height: 100%; }
    .sidebar .widget .widget__title {
      position: relative;
      padding-bottom: 8px;
      margin-bottom: 25px; }
      .sidebar .widget .widget__title::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 20px;
        height: 2px;
        background-color: #f05945; }
    .sidebar .widget .map iframe {
      width: 100%; }
  .sidebar .list li {
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5; }
    .sidebar .list li:first-child {
      padding-top: 0; }
    .sidebar .list li:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    .sidebar .list li .caption {
      font-weight: 600; }
      .sidebar .list li .caption::after {
        content: ' :'; }
    .sidebar .list li .info {
      float: right; }
  .sidebar .categories__list li:first-child a {
    padding-top: 0; }
  .sidebar .categories__list li:last-child a {
    padding-bottom: 0;
    border-bottom: none; }
  .sidebar .categories__list li a {
    display: block;
    color: #464646;
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5; }
    .sidebar .categories__list li a span {
      float: right; }
      .sidebar .categories__list li a span::before {
        content: '('; }
      .sidebar .categories__list li a span::after {
        content: ')'; }
  .sidebar .archive__list li:first-child a {
    padding-top: 0; }
  .sidebar .archive__list li:last-child a {
    padding-bottom: 0;
    border-bottom: none; }
  .sidebar .archive__list li a {
    display: block;
    color: #464646;
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5; }
    .sidebar .archive__list li a:hover {
      color: #f05945; }
    .sidebar .archive__list li a span {
      float: right; }
      .sidebar .archive__list li a span::before {
        content: '('; }
      .sidebar .archive__list li a span::after {
        content: ')'; }
  .sidebar .small-post-list .small-post {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px 0;
    border-bottom: 1px solid #e5e5e5; }
    .sidebar .small-post-list .small-post:first-child {
      padding-top: 0; }
    .sidebar .small-post-list .small-post:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    .sidebar .small-post-list .small-post__thumb {
      width: 58px; }
    .sidebar .small-post-list .small-post__content {
      width: calc(100% - 58px);
      padding-left: 20px; }
      .sidebar .small-post-list .small-post__content .post__title a {
        font-size: 16px;
        font-weight: 600; }
  .sidebar .tags {
    margin: -5px -2px; }
    .sidebar .tags a {
      font-size: 16px;
      font-weight: 400;
      color: #464646;
      padding: 10px 20px;
      border: 1px solid #e5e5e5;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      margin: 5px 2px;
      text-transform: capitalize; }
      .sidebar .tags a:hover {
        background-color: #f05945;
        color: #ffffff;
        border-color: #f05945; }

/* sidebar css end */
/* action-sidebar css start */
.action-sidebar {
  background-color: #fff; }
  @media (max-width: 991px) {
    .action-sidebar {
      padding: 30px;
      width: 350px;
      position: fixed;
      top: 75px;
      left: -105%;
      z-index: 99999;
      max-width: 100%;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
      max-height: calc(100vh - 75px);
      overflow: auto;
      scrollbar-width: thin;
      scrollbar-color: darkgrey #e7e7e7; }
      .action-sidebar::-webkit-scrollbar {
        width: 5px; }
      .action-sidebar::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
      .action-sidebar::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 999px;
        -webkit-border-radius: 999px;
        -moz-border-radius: 999px;
        -ms-border-radius: 999px;
        -o-border-radius: 999px; }
      .action-sidebar.active {
        left: 0; } }

.action-widget + .action-widget {
  margin-top: 1.875rem; }

.action-widget__title {
  font-size: 1rem;
  color: #373e4a;
  margin-bottom: 0.9375rem;
  padding-bottom: 0.3125rem;
  border-bottom: 1px solid #d8dfe5;
  position: relative;
  padding-right: 20px;
  cursor: pointer; }
  .action-widget__title::after {
    position: absolute;
    content: "\f107";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    top: 0;
    right: 0;
    font-size: 0.875rem; }

.action-widget__body.scroll--active {
  max-height: 200px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: darkgrey #e7e7e7; }
  .action-widget__body.scroll--active::-webkit-scrollbar {
    width: 5px; }
  .action-widget__body.scroll--active::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .action-widget__body.scroll--active::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 999px;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    -ms-border-radius: 999px;
    -o-border-radius: 999px; }

.action-sidebar-close {
  width: 30px;
  height: 30px;
  background-color: #f05945;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  display: none; }
  @media (max-width: 991px) {
    .action-sidebar-close {
      display: inline-block; } }

.action-sidebar-open {
  padding: 0.5rem 1.5625rem;
  background-color: #f05945;
  color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  display: none; }
  @media (max-width: 991px) {
    .action-sidebar-open {
      display: inline-flex; } }
  .action-sidebar-open i {
    font-size: 1.375rem;
    margin-right: 5px; }

/* action-sidebar css end */
/* property-sidebar css start */
.property-sidebar {
  position: sticky;
  top: 100px; }

.property-widget {
  padding: 0.9375rem;
  background-color: #fff;
  border: 2px solid #c0cad5;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }

.agent {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .agent__thumb {
    width: 70px; }
  .agent__content {
    width: calc(100% - 70px);
    padding-left: 1.25rem; }

.agent-form .form--control {
  height: 45px; }

/* property-sidebar css end */
/* user-sidebar css start */
.user-widget {
  padding: 1.875rem; }
  .user-widget .thumb {
    max-height: 230px;
    overflow: hidden; }

.user-info-list li {
  padding: 6px 0;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.15);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center; }
  .user-info-list li:first-child {
    padding-top: 0; }
  .user-info-list li:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .user-info-list li i {
    width: 30px;
    font-size: 1.375rem; }
  .user-info-list li p {
    width: calc(100% - 30px);
    padding-left: 15px; }

.user-menu-widget {
  margin-top: 1.875rem;
  padding: 0.9375rem;
  background-color: #fff;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.05); }

.user-menu li {
  border-bottom: 1px dashed #dfe4e9; }
  .user-menu li.active {
    border-bottom: none; }
    .user-menu li.active a {
      color: #fff; }
    .user-menu li.active:hover a {
      color: #fff; }
  .user-menu li:last-child {
    border-bottom: none; }
  .user-menu li:hover a {
    background-color: rgba(240, 89, 69, 0.15); }
  .user-menu li a {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    color: #464646;
    padding: 10px 15px; }
    .user-menu li a i {
      font-size: 1.125rem;
      margin-right: 8px; }

/* user-sidebar css end */
/* footer section css start */
.footer {
  position: relative;
  padding-top: 80px;
  z-index: 1; }
  .footer::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.85;
    z-index: -1; }
  .footer__bottom {
    padding: 20px 0;
    border-top: 1px solid #c0cad5;
    margin-top: 50px; }

.footer-logo img {
  max-height: 70px; }

.footer-widget__title {
  margin-bottom: 25px;
  position: relative;
  z-index: 1; }
  .footer-widget__title::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #c0cad5;
    z-index: -1; }
  .footer-widget__title span {
    background-color: #fff; }

.footer-menu-list li + li {
  margin-top: 5px; }

.footer-menu-list li a {
  color: #464646;
  font-size: 0.9375rem; }
  .footer-menu-list li a::before {
    content: '- '; }

.footer-contact-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -8px; }
  .footer-contact-list > li {
    width: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 8px; }
    @media (max-width: 1199px) {
      .footer-contact-list > li {
        width: 100%; } }
    .footer-contact-list > li .icon {
      width: 30px;
      font-size: 1.25rem; }
    .footer-contact-list > li .content {
      width: calc(100% - 30px); }
      .footer-contact-list > li .content a {
        color: #464646;
        word-break: break-all; }

.social-media-list {
  margin: -5px; }
  .social-media-list li {
    padding: 5px; }
    .social-media-list li a {
      font-size: 1.25rem;
      color: #464646; }

/* search result section css start */
.hotel-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;
  box-shadow: 0 0 8px rgba(0, 32, 70, 0.1);
  position: relative; }
  .hotel-card + .hotel-card {
    margin-top: 0.9375rem; }
  @media (max-width: 767px) {
    .hotel-card {
      box-shadow: 0 0 5px rgba(0, 32, 70, 0.2); } }
  .hotel-card__offer-badge {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 100px;
    height: 100px;
    background-color: #f05945;
    color: #fff;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    text-align: right;
    padding-right: 15px;
    padding-top: 10px; }
  .hotel-card__thumb {
    width: 300px; }
    @media (max-width: 1199px) {
      .hotel-card__thumb {
        width: 200px; } }
    @media (max-width: 767px) {
      .hotel-card__thumb {
        width: 100%; } }
    .hotel-card__thumb a {
      height: 100%; }
    .hotel-card__thumb img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -o-object-fit: cover;
      object-position: center;
      -o-object-position: center; }
  .hotel-card__content {
    width: calc(100% - 460px);
    padding: 20px;
    border-top: 1px solid #c0cad5;
    border-bottom: 1px solid #c0cad5; }
    @media (max-width: 1199px) {
      .hotel-card__content {
        width: calc(100% - 360px); } }
    @media (max-width: 767px) {
      .hotel-card__content {
        width: 100%; } }
    .hotel-card__content .title {
      font-size: 1.25rem; }
  .hotel-card__action {
    width: 160px;
    padding: 20px;
    border: 1px solid #c0cad5;
    text-align: center;
    border-radius: 0 8px 8px 0;
    -webkit-border-radius: 0 8px 8px 0;
    -moz-border-radius: 0 8px 8px 0;
    -ms-border-radius: 0 8px 8px 0;
    -o-border-radius: 0 8px 8px 0;
    display: flex;
    justify-content: center;
    align-items: flex-end; }
    @media (max-width: 767px) {
      .hotel-card__action {
        width: 100%;
        border: none; } }
  .hotel-card .price {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1; }

.features-list li {
  font-size: 0.875rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; }
  .features-list li::before {
    content: "\f058";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    color: #28c76f;
    margin-right: 5px; }

.hotel-search-form .input-group {
  border: 1px solid #c0cad5; }

.hotel-search-form .select2-basic ~ .select2-container {
  width: auto !important;
  max-width: auto !important;
  flex: 1 1 auto; }

.hotel-search-form .select2-container--default .select2-selection--single {
  border: none; }
  .hotel-search-form .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 0; }

.hotel-search-form .input-group-text {
  background-color: #fff;
  border: none; }
  .hotel-search-form .input-group-text i {
    font-size: 1.375rem; }

.hotel-search-form .form--control {
  border: none; }
  .hotel-search-form .form--control:focus {
    box-shadow: none; }

/* search result section css end */
/* hotel deatils section css start */
.hotel-details-thumb {
  position: relative;
  height: 350px; }
  .hotel-details-thumb:hover .full-view {
    -webkit-transform: translate(-50%, -50%) scale(1, 1);
    -ms-transform: translate(-50%, -50%) scale(1, 1);
    transform: translate(-50%, -50%) scale(1, 1);
    opacity: 1;
    visibility: visible; }
  .hotel-details-thumb img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center; }
  .hotel-details-thumb .full-view {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0.5, 0.5);
    -ms-transform: translate(-50%, -50%) scale(0.5, 0.5);
    transform: translate(-50%, -50%) scale(0.5, 0.5);
    background-color: rgba(0, 32, 70, 0.85);
    color: #fff;
    padding: 0.5rem 1.875rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 999px;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    -ms-border-radius: 999px;
    -o-border-radius: 999px;
    opacity: 0;
    visibility: hidden; }
    .hotel-details-thumb .full-view i {
      font-size: 1.375rem;
      margin-right: 6px; }

.hotel-details-thumb-slider .slick-arrow {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-top: -20px; }
  .hotel-details-thumb-slider .slick-arrow.prev {
    left: 20px; }
  .hotel-details-thumb-slider .slick-arrow.next {
    right: 20px; }

/* hotel deatils section css end */
/* hotel-details-box css start */
.hotel-details-box {
  padding: 30px 0;
  border-bottom: 1px solid #c0cad5; }
  .hotel-details-box:first-child {
    padding-top: 0; }
  .hotel-details-box:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .hotel-details-box .aminities-list {
    margin-top: 10px; }
    .hotel-details-box .aminities-list li + li {
      margin-top: 6px; }
    .hotel-details-box .aminities-list li {
      font-size: 0.875rem; }
      .hotel-details-box .aminities-list li::before {
        content: "\f058";
        font-family: 'Line Awesome Free';
        font-weight: 900;
        margin-right: 5px;
        color: #28c76f; }

.hotel-details-map {
  padding: 0.9375rem;
  background-color: #fff;
  border: 1px solid #c0cad5;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1); }
  .hotel-details-map iframe {
    width: 100%;
    height: 450px; }

.aminities-grid {
  margin: -15px; }

.aminities-item {
  width: calc(100% / 4);
  padding: 15px; }
  @media (max-width: 1199px) {
    .aminities-item {
      width: calc(100% / 3); } }
  @media (max-width: 575px) {
    .aminities-item {
      width: calc(100% / 2); } }

/* hotel-details-box css end */
/* hotel-details-sidebar css start */
.hotel-details-sidebar {
  margin-top: -150px;
  position: sticky;
  z-index: 1;
  top: 50px; }
  @media (max-width: 991px) {
    .hotel-details-sidebar {
      margin-top: 0; } }

.reserve-widget {
  padding: 0.9375rem;
  background-color: #fff;
  border: 1px solid #c0cad5;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1); }
  .reserve-widget .top {
    padding: 1.25rem;
    background-color: #f1f1f1;
    position: relative;
    overflow: hidden; }
    .reserve-widget .top .hotel-details-offer-badge {
      position: absolute;
      top: -2px;
      right: -2px;
      width: 100px;
      height: 100px;
      background-color: #f05945;
      color: #fff;
      clip-path: polygon(0 0, 100% 0, 100% 100%);
      text-align: right;
      padding-right: 15px;
      padding-top: 10px; }
  .reserve-widget .price {
    font-weight: 500;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .reserve-widget .price del {
      font-weight: 400;
      font-size: 18px;
      color: #777;
      margin-right: 10px; }
    .reserve-widget .price sub {
      font-weight: 400;
      font-size: 50%;
      color: #777;
      margin-left: 5px; }

.book-widget {
  padding: 1.875rem; }
  .book-widget i {
    font-size: 3.5rem;
    line-height: 1; }

/* hotel-details-sidebar css end */
/* account section css start */
.account-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-height: 100vh; }
  .account-section .left {
    padding: 100px 50px;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    order: 1; }
    @media (max-width: 991px) {
      .account-section .left {
        width: 100%;
        order: 2; } }
    @media (max-width: 575px) {
      .account-section .left {
        padding: 90px 30px; } }
    .account-section .left .top-el, .account-section .left .bottom-el {
      position: absolute;
      left: 0;
      width: 100%;
      z-index: -1;
      opacity: 0.25; }
      .account-section .left .top-el img, .account-section .left .bottom-el img {
        max-height: 200px; }
    .account-section .left .top-el {
      top: 0;
      text-align: right; }
    .account-section .left .bottom-el {
      bottom: 0; }
    .account-section .left .account-form-area {
      width: 60%; }
      @media (max-width: 1650px) {
        .account-section .left .account-form-area {
          width: 70%; } }
      @media (max-width: 1399px) {
        .account-section .left .account-form-area {
          width: 100%; } }
      @media (max-width: 991px) {
        .account-section .left .account-form-area {
          width: 60%; } }
      @media (max-width: 767px) {
        .account-section .left .account-form-area {
          width: 80%; } }
      @media (max-width: 575px) {
        .account-section .left .account-form-area {
          width: 100%; } }
  .account-section .account-logo img {
    max-height: 110px; }
  .account-section .right {
    width: 60%;
    position: relative;
    z-index: 1;
    padding: 100px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 2; }
    @media (max-width: 991px) {
      .account-section .right {
        width: 100%;
        order: 1; } }
    .account-section .right .content {
      width: 50%; }
      @media (max-width: 1399px) {
        .account-section .right .content {
          width: 80%; } }
      @media (max-width: 575px) {
        .account-section .right .content {
          width: 100%; } }
    .account-section .right .title {
      font-size: 4.5rem;
      line-height: 1.2; }
      @media (max-width: 1199px) {
        .account-section .right .title {
          font-size: 3.875rem; } }
      @media (max-width: 991px) {
        .account-section .right .title {
          font-size: 3.5rem; } }
      @media (max-width: 767px) {
        .account-section .right .title {
          font-size: 3rem; } }
      @media (max-width: 575px) {
        .account-section .right .title {
          font-size: 2.625rem; } }
    .account-section .right::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #002046;
      opacity: 0.75;
      z-index: -1; }

/* account section css end */
/* contact section css start */
.contact-card {
  padding: 1.875rem;
  background-color: #fff;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1);
  border: 1px solid #c0cad5;
  height: 100%; }
  .contact-card__header {
    padding-bottom: 0.625rem;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid #c0cad5;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center; }
    .contact-card__header .icon {
      width: 55px; }
      .contact-card__header .icon i {
        font-size: 2.625rem; }
    .contact-card__header .title {
      width: calc(100% - 55px); }
  .contact-card a {
    color: #464646; }

.social-media-list {
  margin: -5px -10px; }
  .social-media-list li {
    padding: 5px 10px; }
    .social-media-list li a {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff; }
      .social-media-list li a:hover {
        color: #fff; }

.dot--bg {
  position: relative;
  z-index: 1; }
  .dot--bg::before {
    position: absolute;
    content: '';
    background: transparent;
    background-image: radial-gradient(#002046 25%, transparent 0), radial-gradient(#002046 25%, transparent 0);
    background-size: 10px 10px;
    background-position: 0 0, 10px 10px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.05;
    z-index: -1; }

/* contact section css end */
/* map section css start */
.google-map-section {
  position: relative;
  filter: grayscale(100%);
  -moz-backface-visibility: hidden; }
  .google-map-section:hover {
    filter: grayscale(0%); }
  .google-map-section iframe {
    width: 100%;
    height: 450px; }

/* map section css end */
/* contact form section css start */
.contact-form {
  padding: 50px;
  background-color: #fff;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1);
  border: 1px solid #c0cad5; }
  @media (max-width: 1199px) {
    .contact-form {
      padding: 30px; } }
  @media (max-width: 480px) {
    .contact-form {
      padding: 20px; } }

/* contact form section css end */
